
import { configureStore } from '@reduxjs/toolkit';
import FooterReducer from './Reducers/FooterReducer';
import APIErrorReducer from './Reducers/APIErrorReducer';
import UserReducer from './Reducers/UserReducer';
import MasterReducer from './Reducers/MasterReducer';


const store = configureStore({
  reducer: {
    error:APIErrorReducer,
    footer: FooterReducer,
    user:UserReducer,
    master:MasterReducer
  },
});

export default store;




