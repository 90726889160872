import React, { useState } from "react";
import { Drawer, FloatButton } from "antd";
import { SettingOutlined } from "@ant-design/icons";

const CustomDrawer = ({ title, children, showDrawer, onClose,open }) => {
  return (
    <>
      <FloatButton
        tooltip={<div>Filter Data</div>}
        icon={<SettingOutlined />}
        onClick={showDrawer}
        className="bg-blue-900 text-white"
        type="primary"
      />
      <Drawer
        title={title}
        placement="right"
        onClose={onClose}
        open={open}
        width={500}
      >
        {children}
      </Drawer>
    </>
  );
};

export default CustomDrawer;
