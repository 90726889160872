import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Form,
  notification,
  Skeleton,
  Spin,
  Input,
  Select,
  Radio,
} from "antd";
import { useStoreUpdaterWithAPI } from "../../../../../../Hooks/UseStoreUpdaterWithApi";
import { useNavigate, useLocation } from "react-router-dom";
import { APIConstants } from "../../../../../../Common/APIConstants";
import { MeterGrid, SiteGrid } from "../../../../../../Common/UIConstants";
import { useSelector,useDispatch } from "react-redux";
import { actionSiteCategory } from "../../../../../../Store/Reducers/MasterReducer";

import {Mappers} from "../../../../../../Common/Common"

const MeterConfig = () => {
  const { loading, makeRequest } = useStoreUpdaterWithAPI();
  const token = useSelector((state) => state.user.token);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [addFormData, setAddFormData] = useState({});
  const [siteList, setSiteList] = useState({});
  const [meterModel, setMeterModel] = useState({})
  const [form] = Form.useForm();
  const [isEditForm, setIsEditForm] = useState(false);
  const rs_site_category = useSelector((S) => S.master.siteCategory);

  const rs_meter_model = useSelector((state) => state.master.meterModelCategory);

    const [updateFormData, setUpdateFormData] = useState({
    meteR_METERID: "",
    metername: "",
    sitename: "",
    metermodel: "",
    meterSupplier: "",
    department: "",
    startingaddress: "",
    numberofregisters: "",
    baudRate: "",
    meterlocation: "",
    active: "",
    meterTypeID: "",
  });

 
  const staticMeterModels = [
    { label: "EN8400", value: "EN8400" },
    { label: "LG5120D", value: "LG5120D" },
    { label: "Masibus", value: "Masibus" },
    { label: "Conzerve EM6433", value: "Conzerve EM6433" },
    { label: "Conzerve PMS100", value: "Conzerve PMS100" },
    { label: "Conzerve EM64100NG", value: "Conzerve EM64100NG" },
    { label: "Conzerve EM64100", value: "Conzerve EM64100" },
    { label: "ION6200", value: "ION6200" },
    { label: "PM172E", value: "PM172E" },
    { label: "PM130EH", value: "PM130EH" },
    { label: "ALEN6200", value: "ALEN6200" },
    { label: "Others", value: "Others" },
    { label: "Conzerve EM6436H", value: "Conzerve EM6436H" },
  ];

  const staticMeterSupplier = [
    { label: "Conzerve", value: "Conzerve" },
    { label: "Secure", value: "Secure" },
    { label: "SATEC", value: "SATEC" },
    { label: "MERLIN GERIN", value: "MERLIN GERIN" },
    { label: "KRYKARD", value: "KRYKARD" },
    { label: "ICD", value: "ICD" },
    
  ];

  const staticCategoryName = [
    { label: "SBR Horizon", value: "SBR Horizon" },
   
    
  ];

  const staticBaudRate = [
    { label: "4800 Baud Rate", value: "4800 Baud Rate" },

    { label: "9600 Baud Rate", value: "4800 Baud Rate" },
    { label: "14400 Baud Rate", value: "14400 Baud Rate" },
    { label: "19200 Baud Rate", value: "19200 Baud Rate" },
    { label: "38400 Baud Rate", value: "38400 Baud Rate" },
    { label: "56000 Baud Rate", value: "56000 Baud Rate" },
    { label: "57600 Baud Rate", value: "57600 Baud Rate" },
    { label: "115200 Baud Rate", value: "115200 Baud Rate" },
    
    
  ];


  const staticMeterLocation = [
    { label: "Incomer", value: "Incomer" },

    { label: "Feeder", value: "Feeder" },
    { label: "DG", value: "DG" },
    { label: "Load", value: "Load" },
    { label: "Sub Load", value: "Sub Load" },
    { label: "Compressor", value: "Compressor" },
    
    
    
  ];

  useEffect(() => {
    if (rs_site_category && rs_site_category.length > 0) {
      const mappedSites = Mappers.mapSiteCategories(rs_site_category);
      setSiteList(mappedSites);
    } else {
      makeRequest({
        type: APIConstants.GET,
        url: APIConstants.GET_CONVERTER,
        action: actionSiteCategory,
        mapper: Mappers.mapSiteCategories,
        token: token,
      }).then((res) => {
        setSiteList(res);
      });
    }

    
  }, []);

  const handleClear = () => {
    form.resetFields();
  };

  const handleNotification = (msg) => {
    notification.open({
      message: `${msg}`,
    });
  };

  
 

  // "meteR_METERID": 0,
  //   "metername": "string",
  //   "sitename": "string",
  //   "metermodel": "string",
  //   "meterSupplier": "string",
  //   "department": "string",
  //   "startingaddress": 0,
  //   "numberofregisters": 0,
  //   "baudRate": 0,
  //   "meterlocation": "string",
  //   "active": "string",
  //   "meterTypeID": true

  const moduleTypeMapping = {
    temperature: 1,
    air: 2,
    water: 3,
    electrical: 4,
    steam: 5,
  };

  const meterTypeIDMapping = {
    temperature: true,
    air: true,
    water: true,
    electrical: true,
    steam: true,
  };

  const handleAddFormChange = (changedValues, allValues) => {
    setAddFormData(allValues);
  };

  const handleSiteChange = (value) => {
    setAddFormData((prevState) => ({
      ...prevState,
      sitename: value,
    }));
  };
  
  // const handleMeterModel = (value) => {
  //   setAddFormData((prevState) => ({
  //     ...prevState,
  //     metermodel: value,
  //   }));
  // };
  // meteR_METERID": 0,
  // "sitE_ID": 0,
  // "metername": "string",
  // "sitename": "string",
  // "metermodel": "string",
  // "meterSupplier": "string",
  // "department": "string",
  // "startingaddress": 0,
  // "numberofregisters": 0,
  // "baudRate": 0,
  // "meterlocation": "string",
  // "active": "string",
  // "departmenT_ID": 0,
  // "ipAddress": "string",
  // "enabled": true,
  // "description": "string"


  const handleAddFormSubmit = async () => {
    try {
      const formData = {
        meteR_METERID: Number(addFormData.meterid),
        metername: addFormData.metername,
        sitename: addFormData.sitename,
        metermodel: addFormData.metermodel,
        meterSupplier: addFormData.metersupplier,
        departmenT_ID: addFormData.departmentid,
        department: addFormData.department,
        startingaddress: moduleTypeMapping[addFormData.startingaddress], //number
        numberofregisters: moduleTypeMapping[addFormData.noofregisters], //number
        baudRate: moduleTypeMapping[addFormData.baudrate], //number
        meterlocation: addFormData.meterlocation,
        active: addFormData.active,
        meterTypeID: meterTypeIDMapping[addFormData.metertype], //boolean
        ipaddress:addFormData.ipaddress,
        description: addFormData.description,
      };


      console.log(formData);
      await makeRequest({
        type: APIConstants.POST,
        url: APIConstants.INSERT_METER_DETAILS,
        body: formData,
        token: token,
      });

      handleNotification("Successfully Saved");
    } catch (err) {
      console.error(err);
    }
  };

  const handleUpdateFormChange = (changedValues, allValues) => {
    setIsEditForm(true);
    setUpdateFormData((prev) => ({
      ...prev,
      ...allValues,
    }));
  };

  const handleUpdateFormSubmit = async (update) => {
    try {
      const formData = {
        meteR_METERID: Number(update.meterId),
        metername: update.meterName,
        sitename: update.converterName,
        metermodel: update.meterModal,
        meterSupplier: update.meterSupplier,
        department: update.siteid,
        startingaddress: Number(update.startingAddress),
        numberofregisters: Number(update.numberofRegisters),
        baudRate: Number(update.baudRate),
        meterlocation: update.meterLocation,
        active: update.action,
        meterTypeID: update.moduleType,
        
      };

      // console.log(formData)
      await makeRequest({
        type: APIConstants.PUT,
        url: APIConstants.UPDATE_METER_DETAILS,
        body: formData,
        token: token,
      });

      handleNotification("Successfully Updated");
    } catch (err) {
      console.error(err);
    }
  };

  // const handleDeletePopup = (record) => {
  //   setSelectedDeleteData(record.groupID);
  // };

  // SITE_ID=3&DEPARTMENT_ID=4&METERID=5
  // const handleDeleteSubmit = (SITE_ID) => {
  //   try {
  //     makeRequest({
  //       type: APIConstants.DELETE,
  //       url: `${APIConstants.DELETE_METER_DETAILS}?SITE_ID=${SITE_ID}&DEPARTMENT_ID=${DEPARTMENT_ID}&METERID=${METERID}`,
  //     });
  // token:token
  //     const updatedSiteList = siteList.filter(
  //       (item) => item.SITE_ID !==
  //     );
  //     setSiteList(updatedFlowGroupList);

  //     handleNotification("Successfully Deleted");
  //     form.resetFields();
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };
  
  return (
    <div>
      <Card>
        <div className="flex justify-start">
          <h2> METER CONFIGURATION </h2>
        </div>

        <div className="form-input-details space-y-2 px-2 flex justify-center  p-3">
          <Form
            form={form}
            onFinish={handleAddFormSubmit}
            name="add-group-form"
            className="font-bold w-[1200px]"
            labelCol={{ span: 8 }}
            layout="horizontal"
            onValuesChange={handleAddFormChange}
            initialValues={{}}
          >
            <div className="grid grid-cols-3 gap-4">
              <Form.Item
                label="Meter ID"
                name="meterid"
                rules={[{ required: true, message: "Please enter group name" }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Meter Name"
                name="metername"
                rules={[
                  { required: true, message: "Please select group module" },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Port No"
                name="portno"
                rules={[
                  { required: true, message: "Please select report status" },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="IP Address"
                name="ipaddress"
                rules={[
                  { required: true, message: "Please select report status" },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Meter Model"
                name="metermodel"
                rules={[
                  { required: true, message: "Please select report status" },
                ]}
              >
              <Select>
              {staticMeterModels?.map((item) => (
                <Select.Option
                  key={item?.value}
                  value={item?.value}
                >
                  {item?.label}
                </Select.Option>
              ))}
            </Select>
              </Form.Item>

              <Form.Item
                label="Meter Supplier"
                name="metersupplier"
                rules={[
                  { required: true, message: "Please select group module" },
                ]}
              >
                <Select>
              {staticMeterSupplier?.map((item) => (
                <Select.Option
                  key={item?.value}
                  value={item?.value}
                >
                  {item?.label}
                </Select.Option>
              ))}
            </Select>
                
              </Form.Item>

              <Form.Item
                label="Converter Name"
                name="sitename"
                rules={[
                  { required: true, message: "Please select report status" },
                ]}
              >
             <Select
                  options={siteList}
                 
                  onChange={handleSiteChange}
                />
              </Form.Item>

              <Form.Item
                label="Category Name"
                name="categoryname"
                rules={[
                  { required: true, message: "Please select report status" },
                ]}
              >
                <Select
                  options={staticCategoryName}
                 
                  onChange={handleSiteChange}
                />
              </Form.Item>


              <Form.Item
                label="Starting Address"
                name="startingaddress"
                rules={[
                  { required: true, message: "Please select report status" },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="No of Registers"
                name="noofregisters"
                rules={[
                  { required: true, message: "Please select report status" },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Department"
                name="department"
                rules={[
                  { required: true, message: "Please select report status" },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Department ID"
                name="departmentid"
                rules={[
                  { required: true, message: "Please select report status" },
                ]}
              >
                <Input />
              </Form.Item>


              <Form.Item
                label="Description"
                name="description"
                rules={[
                  { required: true, message: "Please select report status" },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Baud Rate"
                name="baudrate"
                rules={[
                  { required: true, message: "Please select report status" },
                ]}
              >
                <Select
                  options={staticBaudRate}
                 
                  onChange={handleSiteChange}
                />
              </Form.Item>

              <Form.Item
                label="Meter Location"
                name="meterlocation"
                rules={[
                  { required: true, message: "Please select report status" },
                ]}
              >
               <Select
                  options={staticMeterLocation}
                 
                  onChange={handleSiteChange}
                />
             
              </Form.Item>

              <Form.Item
                label="Meter Type"
                name="metertype"
                rules={[
                  { required: true, message: "Please select report status" },
                ]}
              >
                <Select>
                  <Option value="temperature" name="temperature">
                    Temperature
                  </Option>
                  <Option value="air" name="air">
                    Air
                  </Option>
                </Select>
              </Form.Item>

              <Form.Item
                label="Active"
                name="active"
                rules={[
                  { required: true, message: "Please select report status" },
                ]}
              >
                <Radio.Group>
                  <Radio value="true">Yes</Radio>
                  <Radio value="false">No</Radio>
                </Radio.Group>
              </Form.Item>
            </div>

            <Form.Item className="flex justify-end">
              <Button htmlType="submit" className="blue-btn mr-2">
                OK
              </Button>
              <Button className="blue-btn" onClick={handleClear}>
                CANCEL
              </Button>
            </Form.Item>
          </Form>

          {isEditForm ? (
            <Form
              form={form}
              onFinish={handleAddFormSubmit}
              name="add-group-form"
              className="font-bold w-[1200px]"
              labelCol={{ span: 8 }}
              layout="horizontal"
              onValuesChange={handleAddFormChange}
              initialValues={{}}
            >
              <div className="grid grid-cols-3 gap-4">
                <Form.Item
                  label="Meter ID"
                  name="meterId"
                  rules={[
                    { required: true, message: "Please enter group name" },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Meter Name"
                  name="meterName"
                  rules={[
                    { required: true, message: "Please select group module" },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Port No"
                  name="portNo"
                  rules={[
                    { required: true, message: "Please select report status" },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Meter Model"
                  name="meterModel"
                  rules={[
                    { required: true, message: "Please select report status" },
                  ]}
                >
                  <Select>
                    <Option value="temperature" name="temperature">
                      Temperature
                    </Option>
                    <Option value="air" name="air">
                      Air
                    </Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Meter Supplier"
                  name="meterSupplier"
                  rules={[
                    { required: true, message: "Please select group module" },
                  ]}
                >
                  <Select>
                    <Option value="temperature" name="temperature">
                      Temperature
                    </Option>
                    <Option value="air" name="air">
                      Air
                    </Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Converter Name"
                  name="converterName"
                  rules={[
                    { required: true, message: "Please select report status" },
                  ]}
                >
                  <Select>
                    <Option value="temperature" name="temperature">
                      Temperature
                    </Option>
                    <Option value="air" name="air">
                      Air
                    </Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  label="IP Address"
                  name="ipaddress"
                  rules={[
                    { required: true, message: "Please select report status" },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Starting Address"
                  name="startingAddress"
                  rules={[
                    { required: true, message: "Please select report status" },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="No of Registers"
                  name="noofRegisters"
                  rules={[
                    { required: true, message: "Please select report status" },
                  ]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Baud Rate"
                  name="baudRate"
                  rules={[
                    { required: true, message: "Please select report status" },
                  ]}
                >
                  <Select>
                    <Option value="temperature" name="temperature">
                      Temperature
                    </Option>
                    <Option value="air" name="air">
                      Air
                    </Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Meter Location"
                  name="meterLocation"
                  rules={[
                    { required: true, message: "Please select report status" },
                  ]}
                >
                  <Select>
                    <Option value="temperature" name="temperature">
                      Temperature
                    </Option>
                    <Option value="air" name="air">
                      Air
                    </Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Meter Type"
                  name="meterType"
                  rules={[
                    { required: true, message: "Please select report status" },
                  ]}
                >
                  <Select>
                    <Option value="temperature" name="temperature">
                      Temperature
                    </Option>
                    <Option value="air" name="air">
                      Air
                    </Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Active"
                  // name="metertype"
                  rules={[
                    { required: true, message: "Please select report status" },
                  ]}
                >
                  <Radio.Group>
                    <Radio value="yes">Yes</Radio>
                    <Radio value="no">No</Radio>
                  </Radio.Group>
                </Form.Item>
              </div>

              <Form.Item className="flex justify-end">
                <Button htmlType="submit" className="blue-btn mr-2">
                  OK
                </Button>
                <Button className="blue-btn" onClick={handleClear}>
                  CANCEL
                </Button>
              </Form.Item>
            </Form>
          ) : null}
        </div>

        <div className="flex justify-start">
          <h2> METER GRID</h2>
        </div>
        <div className="flex justify-start mt-4">
        <Select
                  options={siteList}
                  placeholder="Select a site"
                  onChange={(value) => form.setFieldsValue({ sitename: value })}
                />
        </div>

        <div className="overflow-auto mt-4">
          <Spin indicator={<Spin size="large" />} spinning={loading}>
            <MeterGrid

            // handleDeletePopup={handleDeletePopup}
            // handleDeleteSubmit={handleDeleteSubmit}
            // siteList={siteList}
            />
          </Spin>
        </div>
      </Card>
    </div>
  );
};

export default MeterConfig;
