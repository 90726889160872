import { all } from "axios";

export const getErrorObjForReducer = (error) => {
  const payloadObj = { statusCode: -1, message: "", additionalInfo: "" };
  if (typeof error.stack == "string" && error.stack.startsWith("AxiosError:")) {
    if (error.code === "ERR_NETWORK") {
      payloadObj.statusCode = "";
      payloadObj.message =
        "Network Error - [Network not available/ API is not in running status/ Certificate error]";
      payloadObj.additionalInfo = `Failed URL : ${error.config.url}\r\n`;
    } else {
      if (
        error?.response?.data?.message ===
        "The given credentials are not authorized for the application"
      ) {
        payloadObj.statusCode = error.response.status;
        payloadObj.message = error.response.data.message;
        payloadObj.additionalInfo = "Please contact administrator for access!";
      } else if (
        error?.code === "ERR_BAD_RESPONSE" &&
        error?.message === "Request failed with status code 500" &&
        error?.response?.data?.message
      ) {
        payloadObj.statusCode = error.response.status;
        payloadObj.message = error.response.data.message;
        payloadObj.additionalInfo = error.response.data.trace;
      } else {
        payloadObj.statusCode = error.response.status;
        payloadObj.message = error.message;
        payloadObj.additionalInfo = `Failed URL : ${error.response.request.responseURL}\r\n`;
        if (typeof error.response.data.statusMessage === "undefined") {
          payloadObj.additionalInfo += error.response.data.message;
        } else {
          payloadObj.additionalInfo += error.response.data.statusMessage;
        }
      }
    }
  } else {
    payloadObj.statusCode = "";
    payloadObj.message = "UI Error : " + error.message;
    payloadObj.additionalInfo = error.stack;
  }
  return payloadObj;
};

export const mapSiteCategories = (allSiteCategories) => {
  const SiteCategories = [];
  allSiteCategories.forEach((S) => {
    SiteCategories.push({
      label: S.sitename,
      value: S.sitename,
      key: S.sitename,
    });
  });
  return SiteCategories;
};

export const mapMeterModel = (allMeterModel) => {
  const MeterModel = [];

  allMeterModel.forEach((M) => {
    MeterModel.push({
      label: M.sitename,
      value: M.sitename,
      key: M.sitename,
    });
  });
};
