/* eslint-disable no-unused-vars */
import { useState } from "react";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  LogoutOutlined,
  LineChartOutlined,
 
  SettingOutlined 
} from "@ant-design/icons";
import { Layout, Menu, Button } from "antd";
const { Header, Sider, Content } = Layout;
import { Outlet, Route, Routes, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';


import Logo from "../../Assets/Images/logo.png"
import LiveChart from "../Screens/Electrical/Charts/LiveModule/LiveTrends/LineChart/LiveChart";




function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

const items = [
 
 
  getItem("LiveModule", "1", <LineChartOutlined />, [
    getItem("Live Trends", "sub1"),
    getItem("Meter Status", "sub2"),
    getItem("Raw Config", "sub5"),
  ]),
  getItem("Configuration", "2", <SettingOutlined />, [
    getItem("Meter Config", "sub3"),
    getItem("Site Config", "sub4"),
  ]),
 

]

export const NavItems = [

  { path: "/temperature-dashboard", id: "1" },
  { path: "/temperature-dashboard/live-trends", id: "sub1" },
  { path: "/temperature-dashboard/meter-status", id: "sub2" },

  { path: "/temperature-dashboard/meter-config", id: "sub3" },
  { path: "/temperature-dashboard/site-config", id: "sub4" },
  { path: "/temperature-dashboard/raw-config", id: "sub5" },
  
];
  // { path: "/steam-dashboard/live-trends", id: "sub1" },
  // { path: "/steam-dashboard/meter-status", id: "sub2" },
const TemperatureLayout = () => {
  const [collapsed, setCollapsed] = useState(true);
  const navigate = useNavigate();
  const [selectedKey, setSelectedKey] = useState(
    localStorage.getItem("selectedKey") || "1"
  );

  const rs_message = useSelector((state) => state.footer.message);

  const handleMenuClick = (e) => {
    const clickedKey = e.key;
    setSelectedKey(clickedKey);
    localStorage.setItem("selectedKey", clickedKey); // Store in local storage
    const selectedItem = NavItems.find((item) => item.id === clickedKey);
    if (selectedItem) {
      navigate(selectedItem.path);
    }
  };

  const handleLogout = () => { 
    clearTokenCookie();
    navigate("/login");
    localStorage.removeItem("selectedKey")
  };

  return (
    <Layout>
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        className={`${
          collapsed ? "overflow-hidden" : "overflow-auto"
        } h-screen z-50`}
        onCollapse={(value) => setCollapsed(value)}
        style={{
          position: "sticky",
          top: 0,
          left: 0,
          bottom: 0,
          paddingRight: 4,
          paddingLeft: 4,
          background:
            "linear-gradient(165deg, rgba(49,204,17,1) 0%, rgba(23,199,75,1) 3%, rgba(3,18,68,1) 29%)",
        }}
      >
        <div className="demo-logo-vertical text-white text-2xl font-bold flex space-x-2 py-2 mb-4">
          <div
            className={`${
              collapsed ? "w-14" : "w-14"
            } p-2 ml-2 transition-all duration-300 cursor-pointer`}
            onClick={()=>navigate('/landing-page')}
          >
            {!collapsed && <div className="w-40 transition delay-200">
              <img width="100%" height="100%" src={Logo} alt="logo" />
            </div>}
          </div>
          
        </div>
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={[selectedKey]} 
          items={items}
          className="bg-transparent font-bold z-50"
          onClick={handleMenuClick}
        />
      </Sider>
      <Layout className="site-layout">
        <Header className="sticky top-0 w-full bg-blue-900 z-10 shadow-md text-white">
          <i
            onClick={() => setCollapsed(!collapsed)}
            className=" cursor-pointer rounded-lg text-2xl w-8 h-8 absolute top-2 left-3 text-white"
          >
            {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          </i>
          
          <p className="text-2xl flex justify-center items-center h-16 pr-4 font-bold">
          SalComp
          </p>
          <div className="flex flex-col justify-end items-center space-x-4 cursor-pointer font-semibold">
            <p className="absolute -top-4 right-8 font-bold">admin</p>
            <i
              className="text-xl absolute top-5 right-10 cursor-pointer"
              onClick={handleLogout}
            >
              <LogoutOutlined />
            </i>
          </div>
        </Header>
        <Content className="h-full">
          <div className="p-6 bg-slate-100 h-full overflow-auto">
            <Routes>
              <Route path="/" element={<LiveChart />} />
             
            </Routes>
            <Outlet />
          
          </div>
        =
          
        </Content>
  <div className="min-h-fit">
            <div className="h-5 bg-blue-900">
              <p className=" font-mono text-sm text-white">{rs_message}</p>
            </div>
          </div>

      </Layout>

    </Layout>


  );
};
export default TemperatureLayout;
