import dayjs from "dayjs"

export const setUTCDate = (dateString) => {
  const result = [];
  dateString?.forEach((item) => {
    const [datePart, timePart] = item?.split(" ");
    const [hour, minute, second] = timePart.split(":").map(Number);
    const date = new Date(item);
    date.setUTCHours(hour, minute, second, 0);
    result.push(date.toISOString());
  });
  return result;
};

export function generatedISOFormatDateString (date) {
  // console.log(date)
  const currentDate =  date.toLocaleString("en-US", { hour12: false });
  const [month, day, rem] = currentDate.split("/");
  const [year, time] = rem?.split(",");
  const [hour, minute, seconds] = time.split(":");

  const formattedDate = `${year}-${month}-${day}T${hour}:${minute}:${seconds}.000Z`

  return formattedDate
}
export function generateISOFormatDateString(date) {
  // console.log(date);
  const padZero = (num) => (num < 10 ? `0${num}` : num);

  const year = date.getFullYear();
  const month = padZero(date.getMonth() + 1);
  const day = padZero(date.getDate());
  const hour = padZero(date.getHours());
  const minute = padZero(date.getMinutes());
  const second = padZero(date.getSeconds());

  const formattedDate = `${year}-${month}-${day}T${hour}:${minute}:${second}.000Z`;

  return formattedDate;
}


export function calculateDateRangeFromDate(date, minutesBefore, minutesAfter) {
  // console.log(date,minutesAfter,minutesBefore)
  const fromDate = new Date(date);
  const toDate = new Date(date);
  // console.log("fromdate"+fromDate)

  const fromMin = fromDate.getMinutes() - minutesBefore;
  const toMin = toDate.getMinutes() + minutesAfter;

  fromDate.setMinutes(fromMin);
  toDate.setMinutes(toMin);
  // console.log('helo'+fromDate)

  // Set current hours
  // const currentHours = date.getHours();
  // fromDate.setUTCHours(currentHours);
  // toDate.setUTCHours(currentHours);

  const formattedFromDate = generateISOFormatDateString(fromDate)
  // console.log(formattedFromDate) 
  const formattedToDate = generatedISOFormatDateString(toDate)

  return {
    from: formattedFromDate,
    to: formattedToDate,
  };
}

export function filterDataByTimeRange(data, timeRangeMinutes) {
  const timeRangeMilliseconds = timeRangeMinutes * 60 * 1000;
  const currentTime = new Date();

  currentTime.setUTCHours(currentTime.getHours());
  currentTime.setUTCMinutes(currentTime.getMinutes());
  currentTime.setUTCSeconds(currentTime.getSeconds());
  currentTime.setUTCMilliseconds(currentTime.getMilliseconds());

  return data.filter((item) => {
    const itemTime = new Date(item.CreatedON);
    // if range is 15 filter the last 15 mins data
    return currentTime - itemTime <= timeRangeMilliseconds;
  });
}

const data = [
  {
    FlowRate: 967,
    Cumulative: 3433267,
    CreatedON: "2023-09-23T07:00:00.007Z",
    SITENAME: "M66 TBM & CP",
    METERID: 1,
  },
  {
    FlowRate: 789,
    Cumulative: 3433258,
    CreatedON: "2023-09-23T07:15:00.003Z",
    SITENAME: "M66 TBM & CP",
    METERID: 1,
  },
  {
    FlowRate: 1262,
    Cumulative: 3433242,
    CreatedON: "2023-09-23T07:20:00.403Z",
    SITENAME: "M66 TBM & CP",
    METERID: 1,
  },
  {
    FlowRate: 909,
    Cumulative: 3433232,
    CreatedON: "2023-09-23T07:30:00.820Z",
    SITENAME: "M66 TBM & CP",
    METERID: 1,
  },
  {
    FlowRate: 830,
    Cumulative: 3433223,
    CreatedON: "2023-09-23T07:35:34.283Z",
    SITENAME: "M66 TBM & CP",
    METERID: 1,
  },
  {
    FlowRate: 772,
    Cumulative: 3433213,
    CreatedON: "2023-09-23T07:45:55.750Z",
    SITENAME: "M66 TBM & CP",
    METERID: 1,
  },
  {
    FlowRate: 774,
    Cumulative: 3433215,
    CreatedON: "2023-09-23T08:55:55.750Z",
    SITENAME: "M66 TBM & CP",
    METERID: 1,
  },
];

export function groupDataByInterval(data, inter, period, date) {
  const groupedData = {};
  const firstSelected = data[0]

  const firstRecordCreatedONArray = [];
  const chartArray = [];

  let intervalInMilliseconds;

  switch (period) {
    case "month":
      intervalInMilliseconds = inter * 30.44 * 24 * 60 * 60 * 1000; // Months
      break;
    case "week":
      intervalInMilliseconds = inter * 7 * 24 * 60 * 60 * 1000; // Weeks
      break;
    case "day":
      intervalInMilliseconds = inter * 24 * 60 * 60 * 1000; // Days
      break;
    case "min":
      intervalInMilliseconds = inter * 60 * 1000; // Minutes
      break;
    default:
      throw new Error("Invalid period");
  }

  // let intervalStartTime = new Date(data[0].createdON).getTime()
  let intervalStartTime = new Date(date).getTime()
  let startTime = new Date(intervalStartTime)


  // group the item based on meter name and interval
  data?.forEach((item) => {
    const timestamp = new Date(item.createdON)
    // const intervalKey = new Date(
    //   Math.floor(timestamp / intervalInMilliseconds) * intervalInMilliseconds
    // ).toLocaleString()
    if(timestamp > startTime){
      startTime = new Date(new Date(startTime).getTime()+intervalInMilliseconds)
    }

    const intervalKey = new Date(startTime).toLocaleString()
    const intervalDate = new Date(startTime - intervalInMilliseconds).toLocaleString()

    if (!groupedData[item.metername]) {
      groupedData[item.metername] = {};
    }

    if (!groupedData[item.metername][intervalKey]) {
      groupedData[item.metername][intervalKey] = {
        records: [],
      };
    }

    groupedData[item.metername][intervalKey]?.records.push({
      Cumulative: item.cumulative,
    });
    if (item.meterid === firstSelected.meterid && groupedData[item.metername][intervalKey].records.length === 1) {
      console.log(groupedData[item.metername][intervalKey])
      if(!firstRecordCreatedONArray.includes(intervalDate)){
        firstRecordCreatedONArray.push(intervalDate);
      }
    }
  });
  // calculate the column value and push into specific array
  for (const metername in groupedData) {
    const meterValues = [];
    
    for (const intervalKey in groupedData[metername]) {
      const records = groupedData[metername][intervalKey].records;
      const firstRecord = records[0];
      const lastRecord = records[records.length - 1];
  
      if (!groupedData[metername][intervalKey].finalValues) {
        groupedData[metername][intervalKey].finalValues = {};
      }
      groupedData[metername][intervalKey].finalValues.Cumulative =
        Math.abs(firstRecord?.Cumulative - lastRecord?.Cumulative);
  
      // Push cumulative value into meterValues
      meterValues.push(groupedData[metername][intervalKey].finalValues.Cumulative);
    }
    // Push an object into chartArray for each meterName
    chartArray.push({
      name: metername,
      data: meterValues,
    });
  }

  console.log(groupedData)
  console.log(firstRecordCreatedONArray)
  return { chartArray, firstRecordCreatedONArray };
}


// const finalData = groupDataByInterval(data, 15, "min");

// console.log(finalData);


export function roundToTwoDecimalPlaces(number) {
  // Round the number to 2 decimal places using mathematical operations
  let roundedNumber = Math.round(number * 100) / 100;

  // Convert the rounded number to a string
  let roundedString = roundedNumber.toString();

  // Ensure that there are always 2 decimal places by appending zeros if needed
  // if (!roundedString.includes(".")) {
  //   roundedString += ".00";
  // } else if (roundedString.split(".")[1].length === 1) {
  //   roundedString += "0";
  // }
  
  return roundedString;
}

export const roundOffValue = (value) => {
  return Math.round(value);
};