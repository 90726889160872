// import { createSlice } from "@reduxjs/toolkit";

// let initialState = { isError: false, statusCode: -1, message: "", additionalInfo: "" };

// const apiSlice = createSlice({
//   name: "api_error",
//   initialState: initialState,
//   reducers: {
//     displayError: (state, action) => {
//       state.isError = true;
//       state.statusCode = action.payload.statusCode;
//       state.message = action.payload.message;
//       state.additionalInfo = action.payload.additionalInfo;
//     },
//     closeError: (state, _action) => {
//       state.isError = false;
//       state.statusCode = "";
//       state.message = "";
//     },
//   },
// });

// export const { displayError, closeError } = apiSlice.actions;
// export default apiSlice.reducer;



import { createSlice } from "@reduxjs/toolkit";

let initialState = { isError: false, statusCode: -1, message: "", additionalInfo: "" };

const apiSlice = createSlice({
  name: "api_error",
  initialState: initialState,
  reducers: {
    displayError: (state, action) => {
      state.isError = true;
      state.statusCode = action.payload.statusCode;
      state.message = action.payload.message;
      state.additionalInfo = action.payload.additionalInfo;
    },
    closeError: (state, _action) => {
      state.isError = false;
      state.statusCode = "";
      state.message = "";
    },
  },
});

export const { displayError, closeError } = apiSlice.actions;
export default apiSlice.reducer;
