
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Form,
  notification,
  Skeleton,
  Spin,
  Input,
  Select,
} from "antd";
import { useStoreUpdaterWithAPI } from "../../../../../../Hooks/UseStoreUpdaterWithApi";
import { useNavigate, useLocation } from "react-router-dom";
import { APIConstants } from "../../../../../../Common/APIConstants";
import { SiteGrid } from "../../../../../../Common/UIConstants";
import { useSelector } from "react-redux";

const SiteConfig = () => {
  const { loading, makeRequest } = useStoreUpdaterWithAPI();
  const token = useSelector((state) => state.user.token)
  const navigate = useNavigate();
  const location = useLocation();
  const [addFormData, setAddFormData] = useState({});
  const [siteList, setSiteList] = useState([])
  const [form] = Form.useForm();



  const fetchSitesList = () => {
    makeRequest({
      type: APIConstants.GET,
      url: APIConstants.GET_CONVERTER,
      token:token
    })
      .then((res) => {
        setSiteList(res);
        // console.log(res)
      })
      
  };

  useEffect(() => {
    fetchSitesList();
  }, []);





  const moduleTypeMapping = {
    temperature: 1,
    air: 2,
    water:3,
    electrical:4,
    steam:5,
  };

  const handleClear = () => {
    form.resetFields();
  };

  const handleNotification = (msg) => {
    notification.open({
      message: `${msg}`,
    });
  };

  const handleAddFormChange = (changedValues, allValues) => {
    setAddFormData(allValues);
  };


  const handleAddFormSubmit = async () => {
    try {
      const formData = {
        sitE_ID: Number(addFormData.siteid),
        sitename: addFormData.sitename,
        port: Number(addFormData.port),
        noofPorts: Number(addFormData.noofport),
        moduleType: moduleTypeMapping[addFormData.moduletype],
       
        isAir: addFormData.moduletype === "air",
        isTemperature: addFormData.moduletype === "temperature",

        isWater: addFormData.moduletype === "water",
        isElectrical: addFormData.moduletype === "electrical",
        isSteam: addFormData.moduletype === "steam",
       
      
      };
console.log(formData)
      await makeRequest({
        type: APIConstants.POST,
        url: APIConstants.INSERT_SITE_NAMES,
        body: formData,
        token:token
      });

     fetchSitesList()
      handleNotification("Successfully Saved");
     
    } catch (err) {
      console.error(err);
    }
  };



  
  const handleDeletePopup = (record) => {
    setSelectedDeleteData(record.groupID);
  };


  // DeleteSiteDetails?SITE_ID=67
  const handleDeleteSubmit = (sitE_ID) => {
    try {
      makeRequest({
        type: APIConstants.DELETE,
        url: `${APIConstants.DELETE_SITE_DETAILS}?SITE_ID=${sitE_ID}`,
        token:token
      });

      const updatedSiteList = siteList.filter(
        (item) => item.sitE_ID !== sitE_ID
      );
      setSiteList(updatedSiteList);

      handleNotification("Successfully Deleted");
      form.resetFields();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <Card>
        <div className="flex justify-start">
          <h2> CONVERTER CONFIGURATION </h2>
        </div>

        <div className="form-input-details space-y-2 px-2 flex justify-center  p-3">
          <Form
            form={form}
            onFinish={handleAddFormSubmit}
            name="add-group-form"
            className="font-bold w-[350px]"
            labelCol={{ span: 8 }}
            layout="horizontal"
            onValuesChange={handleAddFormChange}
            initialValues={{}}
          >
            <Form.Item
              label="Converter ID"
              name="siteid"
              rules={[{ required: true, message: "Please enter group name" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Converter Name"
              name="sitename"
              rules={[
                { required: true, message: "Please select group module" },
              ]}
            >
              <Input />
            </Form.Item>

            {/* <Form.Item
              label="Converter IP"
              name="siteip"
              rules={[
                { required: true, message: "Please select report status" },
              ]}
            >
              <Input />
            </Form.Item> */}

            <Form.Item
              label="Port"
              name="port"
              rules={[
                { required: true, message: "Please select report status" },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="No of Ports"
              name="noofport"
              rules={[
                { required: true, message: "Please select report status" },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Module Type"
              name="moduletype"
              rules={[
                { required: true, message: "Please select group module" },
              ]}
            >
              <Select>
              <Select.Option value="temperature" name="temperature">Temperature</Select.Option>
              <Select.Option value="air" name="air">Air</Select.Option>
                  
                </Select>
            </Form.Item>

            <Form.Item className="flex justify-end">
              <Button htmlType="submit" className="blue-btn mr-2">
                OK
              </Button>
              <Button className="blue-btn" onClick={handleClear}>
                CANCEL
              </Button>
            </Form.Item>
          </Form>
        </div>

        <div className="overflow-auto">
          <Spin indicator={<Spin size="large" />} spinning={loading}>
            <SiteGrid
             
              handleDeletePopup={handleDeletePopup}
              handleDeleteSubmit={handleDeleteSubmit}
              siteList={siteList}
              
            />
          </Spin>
        </div>
      </Card>
    </div>
  );
};

export default SiteConfig;
