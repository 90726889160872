import { createSlice } from "@reduxjs/toolkit";

const initialState = {
 
  siteCategory: [],
 

};

const MasterReducer = createSlice({
  name: "master_reducer",
  initialState: initialState,
  reducers: {
    actionSiteList: (state, action) => {
      state.siteCategory = action.payload;
    },
    actionMeterModel: (state, action) => {
      state.meterModelCategory = action.payload;
    },
   

    
  },
});
export const {
  actionSiteCategory, actionMeterModel
  
} = MasterReducer.actions;
export default MasterReducer.reducer;


