
import React, { useState } from "react";
import { Table } from "antd";

const RawGrid = ({ report, meters }) => {
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 100,
    },
  });

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };

  // Generate dynamic columns based on report data and selected meters
  const generateColumns = () => {
    const columns = [
      { title: "Date", dataIndex: "createdON", key: "createdON", fixed: "left", width: 200 },
      { title: "Meter ID", dataIndex: "meterid", key: "meterid", width: 150 },
      { title: "Meter Name", dataIndex: "metername", key: "metername", width: 150 },
      { title: "Site Name", dataIndex: "sitename", key: "sitename", width: 150 }
    ];

    meters.forEach((meter) => {
      columns.push(
        { title: `${meter} - Temperature`, dataIndex: `temperature`, key: `${meter}_temperature`, width: 150 },
        { title: `${meter} - Relative Humidity`, dataIndex: `rh`, key: `${meter}_rh`, width: 150 }
      );
    });

    return columns;
  };

  const transformData = (data) => {
    return data.map((item) => {
      const transformedItem = { ...item };
      meters.forEach((meter) => {
        transformedItem[`${meter}_temperature`] = item.temperature;
        transformedItem[`${meter}_rh`] = item.rh;
      });
      return transformedItem;
    });
  };

  const columns = generateColumns();
  const transformedReport = transformData(report);

  const totalWidth = columns.reduce((acc, column) => acc + parseInt(column.width || 0, 10), 0);

  return (
    <Table
      columns={columns}
      dataSource={transformedReport}
      onChange={handleTableChange}
      pagination={tableParams.pagination}
      scroll={{ x: totalWidth, y: 450 }}
      rowKey="createdON"
    />
  );
};

export default RawGrid;
