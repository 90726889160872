

const baseURL = process.env.REACT_APP_API_BASE_URL;



export const APIConstants = {
  // * Request Types
  GET: "get",
  PUT: "put",
  POST: "post",
  DELETE: "delete",
  // GET_WITH_TOKEN: "getWithToken",
  API_BASE_URL: baseURL + "",



  //salcomp api paths 
  LIVESTATUS  :baseURL + "/LiveStatus?",
  METERSTATUS  :baseURL + "/MeterStatus?",
  GET_CONVERTER :baseURL + "/GetSiteDetails",
  GET_CONVERTER_METERS :baseURL + "/GetMeterDetails?SiteID=",
  GET_PARAMETERS :baseURL + "/GetParameterDetails",
INSERT_SITE_NAMES :baseURL + "/InsertSiteNamesdetails",

DELETE_SITE_DETAILS :baseURL + "/DeleteSiteDetails",
INSERT_METER_DETAILS :baseURL + "/InsertMeterdetails",
DELETE_METER_DETAILS :baseURL + "/DeleteMeterdetails",
UPDATE_METER_DETAILS :baseURL + "/Updatemeterdetails",
RAW_DATA: baseURL + "/RawData",

  // API Paths(GROUPS)
  LOGIN_API:baseURL + "/api/Users/login",
  GET_APPLICATION_MODULE_DETAILS: baseURL + "/TVSDashBoard/GetApplicationModulesDetails",
  GET_REPORT_STATUS_MASTER_DETAILS: baseURL + "/TVSDashBoard/GetReportStatusMasterDetails",
 GET_FLOW_GROUP_DETAILS: baseURL + "/GetFlowGroupDetails",
 INSERT_FLOW_GROUP_DETAILS: baseURL + "/InsertFlowGroupDetails",
 UPDATE_FLOW_GROUP_DETAILS: baseURL + "/UpdateFlowGroupDetails",
 DELETE_FLOW_GROUP_DETAILS: baseURL + `/DeleteFlowGroupDetails`,
//  http://20.244.40.160:81/DeleteFlowGroupDetails?GroupID=7&IsDelete=true


//API Paths(GROUP_METER)

GET_FLOW_GROUP_METER_DETAILS: baseURL + "/GetFlowGroupMeterDetails",
USP_LIST_SITES: baseURL + "/TVSDashBoard/Getsites",
GET_GROUP_METER_NAME_DETAILS: baseURL +`/TVSDashBoard/NSGetFlowConverterDetails`,
INSERT_FLOW_GROUP_METER_DETAILS: baseURL + "/InsertFlowGroupMeterDetails",
UPDATE_FLOW_GROUP_METER_DETAILS: baseURL + "/UpdateFlowGroupMeterDetails",
DELETE_FLOW_GROUP_METER_DETAILS: baseURL + `/DeleteFlowGroupMeterDetails`,


// API Paths(SUB_METER)

GET_FLOW_SUB_GROUPS_DETAILS:  baseURL + `/GetFlowSubGroupsDetails`,
GET_CALCULATION_TYPE_MASTER_DETAILS: baseURL + "/TVSDashBoard/GetCalculationTypeMasterDetails",
INSERT_FLOW_SUB_GROUP_METER_DETAILS: baseURL + "/InsertFlowSubGroupsDetails",
UPDATE_FLOW_SUB_GROUP_METER_DETAILS:  baseURL + "/UpdateFlowSubGroupsDetails",
DELETE_FLOW_SUB_GROUP_METER_DETAILS:   baseURL + `/DeleteFlowSubGroupsDetails`,



//Electrical APIs
//tree dashboard data
TREE_CHART_DATA_QUERY: baseURL + "/TVSTreeDashBoardData",

//delete childern and entity data
RESET_CALCULATIONS_MUTATION: baseURL + "/DeleteEntityAndChildMeter",



//Plant Chart APIs
TVS_GET_DEPARTMENTS: baseURL + "/TVSDashBoard/GetDepartments",
GET_PLANT_NAME : baseURL + "/Getplantname",
GET_CHART_CONTROL :baseURL + "/Getchartcontrol",
GET_COMBINED_PLANT_DATA : baseURL + "/GetCombinedPlantData",
INSERT_PLANT_NAMES :baseURL + "/InsertplantNames",
INSERT_PLANT_NAME_DETAILS : baseURL + "/InsertplantNamesdetails",
DELETE_PLANT_NAME_DETAILS :baseURL + "/DeletePlantNamedetails",
DELETE_PLANT_NAME_MASTER :baseURL +"/DeletePlantNamemaster",
UPDATE_PLANT_NAME_DETAILS :baseURL + "/UpdatePlantNamedetails",
UPDATE_PLANT_NAME_MASTER :baseURL + "/UpdatePlantNamemaster",


//gauge chart api

// GET_TVS_GAUGE_DASHBOARD_DATA :baseURL + "/TVSDashBoard/GetTVSGauageDashboardData",
// GET_TVS_PRODUCTION_SPC_DETAILS :baseURL + "/TVSDashBoard/GetTVSProductionDataforSPCDetails",

//Live chart APIs

GET_FLOW_CONVERTER_DETAILS :baseURL + "/TVSDashBoard/GetFlowConverterDetails",
GET_FLOW_SECTION_MASTER :baseURL + "/TVSDashBoard/GetFlowSectionMaster",
GET_FLOW_STEAM_GROUP_METER_DETAILS: baseURL + "//TVSDashBoard/GetFlowSteamGropeMeterDetails",
GET_STEAM_CONVERTER_LIVE_CHART:baseURL + "/TVSDashBoard/GetsteamConverterLiveChart"

};

