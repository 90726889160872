import { useState } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { message } from "antd"; 
import { LOGIN, UNAUTHORIZED_PATH } from "../Common/Constants";
import { useNavigate } from "react-router-dom";
import { APIConstants } from "../Common/APIConstants";
import { useCache, useCacheDispatch } from "../Common/CacheContext";
import { setMessage } from "../Store/Reducers/FooterReducer";

export const useStoreUpdaterWithAPI = () => {
  const [loading, setLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate(); 
  const cache = useCache(); 
  const cacheDispatch = useCacheDispatch();

  const handleApiError = (error) => {
    if (error.response?.status === 401) {
      navigate(LOGIN);
      return;
    }
    message.error("An error occurred. Please try again later.");
  };

  const makeRequest = async ({
    type = "",
    url = "",
    body = "",
    token = "",
    action = null,
    mapper = null,
    throwError = false,
    successMessage = "",
    loading = true,
  
    useCache = false,
  }) => {
    if (type === APIConstants.GET) {
      setLoading(loading);
    } else {
      setIsSaving(true);
    }

    const apiKey = type + "~" + url;
    if (useCache) {
      const apiResponseFromCache = cache.get(apiKey);
      if (apiResponseFromCache !== undefined) {
        if (type === APIConstants.GET) {
          setLoading(false);
        } else {
          setIsSaving(false);
        }
        return new Promise((resolve) => {
          if (action != null) {
            dispatch(action(mapper(apiResponseFromCache)));
          }
          resolve(apiResponseFromCache);
        });
      }
    }

    dispatch(setMessage("Please wait while loading data from " + url.replace(APIConstants.API_BASE_URL, "")));

    try {
      const response = await axios({
        method: type,
        url: url,
        data: body,
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
    
      if (successMessage) {
        message.success(successMessage);
      }

      if (action) {
        dispatch(action(mapper ? mapper(response.data) : response.data));
      }

      if (useCache) {
        cacheDispatch({ type: "add", key: apiKey, value: response.data });
      }

      return mapper ? mapper(response.data) : response.data;
    } catch (error) {
      handleApiError(error);
      if (throwError) {
        throw error;
      }
      return undefined;
    } finally {
      setLoading(false);
      if (type !== APIConstants.GET) {
        setIsSaving(false);
      }
      dispatch(setMessage("")); 
    }
  };

  return { loading, isSaving, makeRequest };
};
