import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Outlet, useNavigate } from 'react-router-dom'

const ProtectedRoutes = () => {
    const navigate = useNavigate()
    // let loggedIn = true;
    const loggedIn = useSelector((state)=>state.user.loggedIn)
    // console.log( "kasbdbsaj"+ loggedIn )

    // if (loggedIn) {
    //     return(
    //         <Outlet/>
    //     ) 
    // }else{
    //     <Navigate to={'/login'}/>
    //     console.log('first')
    //     // navigate('/')
    //     console.log('scond')
    // }
    return (
        loggedIn ? <Outlet/> : <Navigate to={'/login'}/>
    )
} 

export default ProtectedRoutes