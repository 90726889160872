import React, { useEffect, useState } from "react";
import { TreeSelect } from "antd";

const TreeSelectCustom = ({
  label,
  data,
  idKey,
  nameKey,
  valueKey,
  selectedValues,
  onSelect,
}) => {
  const [internalSelectedValues, setInternalSelectedValues] = useState(selectedValues);

  const treeData = data?.map((item) => ({
    key: item[idKey]?.toString(),
    title: item[nameKey],
    value: item[valueKey],
  }));


  // Update the selected values when selectedValues prop changes
  useEffect(() => {
    setInternalSelectedValues(selectedValues);
  }, [data,selectedValues]);

  return (
    <div className="flex items-center">
      <label className="pr-3 w-32 text-right">{label || ''}</label>
      <TreeSelect
        mode="multiple"
        placeholder={`Select ${label || ''}`}
        treeData={treeData}
        maxTagCount={2}
        treeCheckable={true}
        className="w-80"
        onChange={(values) => {
          setInternalSelectedValues(values);
          onSelect(values);
        }}
        value={internalSelectedValues}
      />
      
    </div>
  );
};

export default TreeSelectCustom;
