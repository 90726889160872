//here i have written common grids for config module

import React from "react";
import { useState, useEffect } from "react";

import { Popconfirm, Space, Table, Dropdown, Button, Skeleton } from "antd";

// import errorNew from "../../../assets/error/error.png";
import {
  QuestionCircleOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

import { useStoreUpdaterWithAPI } from "../Hooks/UseStoreUpdaterWithApi";

//configuration start
export const SiteGrid = ({
 
  handleDeletePopup,
  handleDeleteSubmit,
 siteList

}) => {
 
console.log(siteList)
 

  const siteListAction = siteList.map((item) => ({
    ...item,
    actions: {
    
      delete: handleDeletePopup,
      confirmDelete: handleDeleteSubmit,
    },
  }));

  

  const columnDefs = [
    {
      headerName: "Name",
      field: "sitename",
      headerClass: "grid-table-header",
      cellStyle: { borderRightColor: " #e2e2e2" },
      flex: 2,
    },
    {
      headerName: "Lan Converter ID",
      field: "sitE_ID", 
      headerClass: "grid-table-header",
      cellStyle: { borderRightColor: " #e2e2e2" },
      flex: 2,
    },
   

    {
      headerName: "Ports",
      field: "port", 
      headerClass: "grid-table-header",
      cellStyle: { borderRightColor: " #e2e2e2" },
      flex: 2,
    },


    {
      headerName: "No of Ports",
      field: "noofPorts",
      headerClass: "grid-table-header",
      cellStyle: { borderRightColor: " #e2e2e2" },
      flex: 2,
    },
    {
      headerName: "Action",
      headerClass: "grid-table-header",
      width: 50,
      cellRenderer: (params) => {
        return (
          <div className="space-x-2">
           

            <Popconfirm
              title="Delete the Group Meter"
              description="Are you sure to delete this Group Meter?"
              icon={<QuestionCircleOutlined style={{ color: "red" }} />}
              onConfirm={() => {
                params.data.actions.confirmDelete(params.data.sitE_ID); // to submit. handleDeleteSubmit
              }}
              trigger="click"
            >
              <Button className="text-red-500">
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const defaultColDef = {
    sortable: true,
    editable: true,
    flex: 1,
    filter: true,
  };

  const { loading } = useStoreUpdaterWithAPI();

  return (
    <div className="App">
      <div
        className="ag-theme-alpine"
        style={{ height: "400px", width: "100%" }}
      >
        <AgGridReact
          columnDefs={columnDefs}
          rowData={siteListAction}
          defaultColDef={defaultColDef}
          pagination={true}
        />
      </div>
    </div>
  );
};



export const MeterGrid = ({
 
  // handleDeletePopup,
  // handleDeleteSubmit,
 

}) => {
 

 

 

  const columnDefs = [
    {
      headerName: "Slno",
      field: "sl",
      headerClass: "grid-table-header",
      cellStyle: { borderRightColor: " #e2e2e2" },
      flex: 2,
    },
    {
      headerName: "Lan Converter Name",
      field: "convertername", 
      headerClass: "grid-table-header",
      cellStyle: { borderRightColor: " #e2e2e2" },
      flex: 2,
    },
   

    {
      headerName: "MeterID",
      field: "meterid", 
      headerClass: "grid-table-header",
      cellStyle: { borderRightColor: " #e2e2e2" },
      flex: 2,
    },


    {
      headerName: "MeterName",
      field: "metername",
      headerClass: "grid-table-header",
      cellStyle: { borderRightColor: " #e2e2e2" },
      flex: 2,
    },
    {
      headerName: "MeterModel",
      field: "metermodel",
      headerClass: "grid-table-header",
      cellStyle: { borderRightColor: " #e2e2e2" },
      flex: 2,
    },
    {
      headerName: "MeterSupplier",
      field: "metersupplier",
      headerClass: "grid-table-header",
      cellStyle: { borderRightColor: " #e2e2e2" },
      flex: 2,
    },
    {
      headerName: "IP Address",
      field: "ipaddress",
      headerClass: "grid-table-header",
      cellStyle: { borderRightColor: " #e2e2e2" },
      flex: 2,
    },
    {
      headerName: "Port No",
      field: "portno",
      headerClass: "grid-table-header",
      cellStyle: { borderRightColor: " #e2e2e2" },
      flex: 2,
    },
    {
      headerName: "Enabled",
      field: "enabled",
      headerClass: "grid-table-header",
      cellStyle: { borderRightColor: " #e2e2e2" },
      flex: 2,
    },
    {
      headerName: "Action",
      headerClass: "grid-table-header",
      width: 50,
      cellRenderer: (params) => {
        return (
          <div className="space-x-2">
            <Button
              onClick={() => {
                // params.data.actions.update(params.data);
              }}
            >
            <EditOutlined />
            </Button>


            <Popconfirm
              title="Delete the Group Meter"
              description="Are you sure to delete this Group Meter?"
              icon={<QuestionCircleOutlined style={{ color: "red" }} />}
              // onConfirm={() => {
              //   params.data.actions.confirmDelete(params.data.SITE_ID); // to submit. handleDeleteSubmit
              // }}
              trigger="click"
            >
              <Button className="text-red-500">
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const defaultColDef = {
    sortable: true,
    editable: true,
    flex: 1,
    filter: true,
  };

  const { loading } = useStoreUpdaterWithAPI();

  return (
    <div className="App">
      <div
        className="ag-theme-alpine"
        style={{ height: "400px", width: "100%" }}
      >
        <AgGridReact
          columnDefs={columnDefs}
          // rowData={flowGroupListWithAction}
          defaultColDef={defaultColDef}
          pagination={true}
        />
      </div>
    </div>
  );
};
