import React, { useEffect, useState } from "react";
import { TreeSelect } from "antd";



const TreeSelectCustomWithSelectAll = ({
  label,
  data,
  idKey,
  nameKey,
  valueKey,
  selectedValues,
  onSelect,
}) => {
  // console.log(data)
  const [internalSelectedValues, setInternalSelectedValues] = useState(selectedValues);
  const [isChecked, setIsChecked] = useState(false);

  const treeData = data?.map((item) => ({
    key: item[idKey]?.toString(),
    title: item[nameKey],
    value: item[valueKey],
  }));
  console.log(treeData)

  // Update the selected values when selectedValues prop changes
  useEffect(() => {
    setInternalSelectedValues(selectedValues);
  }, [data,selectedValues]);

  useEffect(() =>{
    setIsChecked(false)
  },[data])
  
  return (
    // <div className="flex flex-col ">
    //     <div className="flex items-center">
    //         {label && <label className="pr-3 w-32 text-right">{label || ''}</label>}
    //         <TreeSelect
    //             allowClear={true}
    //             placeholder={`Select ${label || ''}`}
    //             treeCheckable={true}
    //             showCheckedStrategy={TreeSelect.SHOW_CHILD}
    //             className="w-80"
    //             dropdownStyle={{ maxHeight: "300px" }}
    //             onChange={(values) => {
    //               setInternalSelectedValues(values);
    //               onSelect(values);
    //             }}
    //             value={internalSelectedValues}
    //             maxTagCount={2}
    //             maxTagPlaceholder={omittedValues =>
    //             `+ ${omittedValues.length} Blocks ...`
    //             }
    //             treeData={[
    //               {
    //                 title:
    //                   internalSelectedValues?.length === treeData?.length ? (
    //                     <span
    //                       onClick={() => setInternalSelectedValues([])}
    //                       style={{
    //                         display: "inline-block",
    //                         color: "#286FBE",
    //                         cursor: "pointer"
    //                       }}
    //                     >
    //                       Unselect all
    //                     </span>
    //                   ) : (
    //                     <span
    //                       // onClick={() => {
    //                       //   const values = treeData.map(item => item.value)
    //                       //   setInternalSelectedValues(values)
    //                       //   onSelect(values);
    //                       // }}
    //                       style={{
    //                         display: "inline-block",
    //                         color: "#286FBE",
    //                         cursor: "pointer"
    //                       }}
    //                     >
    //                       Select all
    //                     </span>
    //                   ),
    //                 value: "",
    //                 disableCheckbox: true,
    //                 disabled: true
    //               },
    //               // ...treeData
    //             ]}
                
    //         />
    //     </div>
    // </div>
    <div className="flex flex-col ">
    <div className="flex items-center">
        {label && <label className="pr-3 w-32 text-right">{label || ''}</label>}
        <TreeSelect
            allowClear={true}
            placeholder={`Select ${label || ''}`}
            treeCheckable={true}
            showCheckedStrategy={TreeSelect.SHOW_CHILD}
            className="w-80"
            dropdownStyle={{ maxHeight: "300px" }}
            onChange={(values) => {
              setInternalSelectedValues(values);
              onSelect(values);
            }}
            value={internalSelectedValues}
            maxTagCount={2}
            maxTagPlaceholder={omittedValues =>
            `+ ${omittedValues.length} Blocks ...`
            }
            treeData={[
              {
                title:
                  internalSelectedValues?.length === treeData?.length ? (
                    <span
                      onClick={() => setInternalSelectedValues([])}
                      style={{
                        display: "inline-block",
                        color: "#286FBE",
                        cursor: "pointer"
                      }}
                    >
                      Unselect all
                    </span>
                  ) : (
                    <span
                      onClick={() => {
                        const values = treeData.map(item => item.value)
                        setInternalSelectedValues(values)
                        onSelect(values);
                      }}
                      style={{
                        display: "inline-block",
                        color: "#286FBE",
                        cursor: "pointer"
                      }}
                    >
                      Select all
                    </span>
                  ),
                value: "",
                disableCheckbox: true,
                disabled: true
              },
              ...treeData
            ]}
            
        />
    </div>
</div>
);
};

export default TreeSelectCustomWithSelectAll;  
