import { Button, Drawer, Skeleton, Checkbox, TreeSelect } from "antd";
import { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { calculateDateRangeFromDate } from "../../../../../../../Utils/DateConverstion";
import TreeSelectCustom from "./TreeSelectCustom";
import CustomDrawer from "../../../../../../Elements/CustomDrawer";
import CustomSelect from "../../../../../../Elements/CustomSelect";
import TreeSelectCustomWithSelectAll from "./TreeSelectCustomWithAllSelect";
import { APIConstants } from "../../../../../../../Common/APIConstants";
import { useStoreUpdaterWithAPI } from "../../../../../../../Hooks/UseStoreUpdaterWithApi";
import { useSelector } from "react-redux";


const LiveChart = () => {
  const token = useSelector((state) => state.user.token)
  const { loading, makeRequest } = useStoreUpdaterWithAPI();
  const [sectionId, setSectionId] = useState();
  const [converterId, setConverterId] = useState();
  const [selectedMeterIds, setSelectedMeterIds] = useState();
  const [selectedValues, setSelectedValues] = useState([]);
  const [skip, setSkip] = useState(true);
  const [open, setOpen] = useState(false);
  const [seriesChartData, setSeriesChartData] = useState();
  const [chartTime, setChartTime] = useState();
  const [singleMeterName, setSingleMeterName] = useState();
  const [sectionError, setSectionError] = useState();
  const [converterError, setConverterError] = useState();
  const [meterError, setMeterError] = useState();
  const [paraError, setParaError] = useState();
  const [chartSkip, setChartSkip] = useState(true);
  const [chartInput, setChartInput] = useState();

  const [submitClicked, setSubmitClicked] = useState(false)
  const [converterList,setConverterList] = useState([])
  const [meterList,setMeterList] = useState([])
  const [report,setReport] = useState([])
  const [parameter,setParameter] = useState([])
  const [createdON,setcreatedON] =  useState([])
  const [temperature,setTemperature] =  useState([])
  const [rhValues,setRhValues] =  useState([])


  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const [chartData, setChartData] = useState({
    series: [
      {
        name: "",
        data: [],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "line",
        zoom: {
          type: "x",
          enabled: true,
          // autoScaleYaxis: true,
        },
        animations: {
          enabled: true,
          easing: "linear",
          dynamicAnimation: {
            speed: 1,
          },
        },
        toolbar: {
          autoSelected: "zoom",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      markers: {
        size: 5,
        sizeOffset: 0,
        hover: {
          size: undefined,
          sizeOffset: 3,
        },
      },
      title: {
        text: "Live Trends Chart",
        align: "left",
      },

      xaxis: {
        categories: [],
        labels: {
          format: "dd-MMM",
        },
        title: {
          text: "Date",
        },
      },
      yaxis: {
        title: {
          text: "Value",
        },
      },
      tooltip: {
        x: {
          show: true,
          format: "dd MMM",
          formatter: undefined,
        },
        y: {
          title: {
            formatter: (
              seriesName,
              { series, seriesIndex, dataPointIndex, w }
            ) => {
              const isFlowRate = seriesName.includes("temperature");
              const kgLabel = isFlowRate ? " Kg/hr" : "Kg";

              // Assuming opts.value contains the actual y-axis value
              return seriesName +" "+ kgLabel;
            },
          },
        },
      },
    },
  });


 
// useEffect(() => {
//   makeRequest({
//     type: APIConstants.GET,
//     url: APIConstants.GET_FLOW_SECTION_MASTER,
//   }).then((res) => {
//     // setFlowSectionList(res);
//     // console.log(res)
//   });

//   makeRequest({
//     type: APIConstants.GET,
//     url: APIConstants.GET_FLOW_CONVERTER_DETAILS,
//   }).then((res) => {
//     // setFlowConverterList(res);
//     // console.log(res)
//   });

//   makeRequest({
//     type: APIConstants.GET,
//     url: APIConstants.GET_FLOW_GROUP_DETAILS,
//   }).then((res) => {
//     // setFlowGroupDetails(res);
//     // console.log(res)
//   });

//   makeRequest({
//     type: APIConstants.GET,
//     url: APIConstants.GET_GROUP_METER_NAME_DETAILS,
//   }).then((res) => {
//     // setNsGetFlowConverterList(res);
//     // console.log(res)
//   });

//   makeRequest({
//     type: APIConstants.GET,
//     url: APIConstants.GET_FLOW_STEAM_GROUP_METER_DETAILS,
//   }).then((res) => {
//     // setFlowSteamGroupMeterList(res);
//     // console.log(res)
//   });

//   makeRequest({
//     type: APIConstants.GET,
//     url: APIConstants.GET_STEAM_CONVERTER_LIVE_CHART,
//   }).then((res) => {
//     // setFlowSteamGroupMeterList(res);
//     // console.log(res)
//   });
// }, []); 


  // const {
  //   data: flowSectionResData,
  //   isLoading: isFlowSectionLoading,
  //   isError: isFlowSectionError,
  //   error: flowError,
  //   refetch: flowRefetch,
  // } = useGetFlowSectionMasterDataQuery();

  // // console.log(flowSectionResData)
  // const {
  //   data: getFlowConverterResData,
  //   isLoading: isFlowConverterLoading,
  //   isError: isFlowConverterError,
  //   error: flowConverterError,
  // } = useGetFlowConverterDetailsQuery();

  // const {
  //   data: flowGroupDetailsResData,
  //   isLoading: isFlowGroupListLoading,
  //   isError: isFlowGroupListError,
  //   error: flowGroupListError,
  //   refetch: flowGroupRefetch,
  // } = useGetFlowGroupDetailsQuery(undefined, { skip });

  // const {
  //   data: nsGetFlowConverterResData,
  //   isLoading: isNSgetFlowConverterLoading,
  //   isError: isnsgetFlowConverterError,
  //   error: nsgetFlowConverterError,
  // } = useNSGetFlowConverterDetailsQuery(
  //   objectToQueryString({ ConverterID: converterGroupId || 1 }),
  //   { skip }
  // );

  // const {
  //   data: getFlowSteamGroupMeterResData,
  //   isLoading: isFlowSteamGroupLoading,
  //   isError: isFlowSteamGroupError,
  //   error: FlowSteamGroupError,
  // } = useGetFlowSteamGropeMeterDetailsQuery(
  //   objectToQueryString({ GroupID: converterGroupId || 23 }),
  //   { skip }
  // );


  // const {
  //   data: steamConverterLiveChartData,
  //   isLoading: isSteamConverterLiveChartLoading,
  //   isError: isSteamConverterLiveChartError,
  //   refetch: refetchChart,
  // } = useGetsteamConverterLiveChartQuery(
  //   chartInput || objectToQueryString(inputData),
  //   { chartSkip }
  // );

  // const getFlowSectionList = (flowSectionResData && flowSectionResData) || [];
  // const getFlowConverterList =
  //   (getFlowConverterResData && getFlowConverterResData) || [];
  // const flowGroupDetailsList =
  //   (flowGroupDetailsResData && flowGroupDetailsResData) || [];
  // const nsGetFlowConverterList =
  //   (nsGetFlowConverterResData && nsGetFlowConverterResData) || [];
  // const getFlowSteamGroupMeterList =
  //   (getFlowSteamGroupMeterResData && getFlowSteamGroupMeterResData) || [];


  useEffect(() => {
    // After 1 second, set skipValue to false
    const timeoutId = setTimeout(() => {
      setSkip(false);
    }, 3000);

    // Clear the timeout if the component unmounts or skipValue is set to false
    return () => clearTimeout(timeoutId);
  }, []);

  useEffect(() => {
    // for converters 
    makeRequest({
      type: APIConstants.GET,
      url: APIConstants.GET_CONVERTER,
      token:token
    }).then((res) => {
      // console.log('converter')
      console.log(res)
      setConverterList(res)
    });

  },[]);
  useEffect(() => {
    // for parameters
    makeRequest({
      type: APIConstants.GET,
      url: APIConstants.GET_PARAMETERS ,
      token:token
    }).then((res) => {
      // console.log('converter')
      // console.log(res)
      setParameter(res)
    });

  },[]);

  useEffect(() => {

    // for meters 
    makeRequest({
      type: APIConstants.GET,
      url: APIConstants.GET_CONVERTER_METERS +`${converterId}`,
      token:token
    }).then((res) => {
      // console.log('meter')
      // console.log(res)
      setMeterList(res)
    });

  },[converterId]);

  const getReposnse =()=>{


    localStorage.setItem("ConverterID", Number(converterId || 3));
    // localStorage.setItem("meterIds", Number( selectedMeterIds || 3));
    localStorage.setItem("meterIds", JSON.stringify(selectedMeterIds || ["1"]));

    // const converterId = Number(converterId || 3);
    // const selectedMeterIds = selectedMeterIds || ["1"];

    // // Set ConverterID and meterIds in localStorage
    // localStorage.setItem("ConverterID", converterId);
    // localStorage.setItem("meterIds", JSON.stringify(selectedMeterIds));

    const currentDate = new Date();
    // const currentDate = new Date().toLocaleString();
    // console.log(currentDate)
    // calculateDateRangeFromDate(currentDate, 15, 2);
    const { from, to } = calculateDateRangeFromDate(currentDate, 15, 2);

    const lgConvId = localStorage.getItem("ConverterID");
    const meterId = localStorage.getItem("meterIds");
    const inputIds = JSON.parse(meterId)?.map((item) => item);

    // console.log(meterId)
    // console.log(inputIds[0])
    const meters=inputIds[0]

    // console.log(from,to)
    // console.log(lgConvId,meterId)

    // const inputIds = JSON.parse(meterId).map((item) => item);
    const ids = inputIds?.map((id) => id).join(",");
    console.log(ids)

    const inputData = {
      ConverterID: Number(lgConvId),
      MeterIds: ids,
      FromDate: from, 
      ToDate:to,
    }; 
    // console.log('getReposnse')
    // console.log(chartInput);
    makeRequest({
      type: APIConstants.GET,
      url: APIConstants.LIVESTATUS +`ConverterorGroupID=${inputData.ConverterID}&MeterIds=${inputData.MeterIds}&FromDate=${inputData.FromDate}&ToDate=${inputData.ToDate}`,
      token:token,
      // body:chartInput
    }).then((res) => {
      // console.log('response')
      // console.log(res)
      setReport(res)
    });
  }

  // useEffect(()=>{
  //   function extractValues(data, key) {
  //     // console.log(data)
  //     // console.log(key)
  //     if (!Array.isArray(data)) {
  //       throw new Error('The provided data is not an array');
  //     }
    
  //     // if (key !== 'temperature' && key !== 'rh') {
  //     //   throw new Error('The provided key is not valid. Choose either "temperature" or "rh"');
  //     // }
    
  //     return data.map(item => {
  //       if (!item.hasOwnProperty(key)) {
  //         throw new Error(`The key "${key}" does not exist in one of the items`);
  //       }
  //       return item[key];
  //     });
  //   }

  //   setTemperature(extractValues(report, 'temperature'));
  //   setRhValues(extractValues(report, 'rh'));
  //   setcreatedON(extractValues(report,'createdON'))
  // },[parameter,report]) 
  // console.log(temperature)
  // console.log(rhValues)


  // useEffect(() => {
  //   setChartData((previousValues) => ({
  //     ...previousValues,
  //     series: {
  //       ...previousValues.series,
  //       data: selectedValues ==1 ? temperature: rhValues,
  //     },
  //     options: {
  //       ...previousValues.options,
  //       xaxis: { 
  //         ...previousValues.options.xaxis,
  //         categories: createdON,
  //       },
  //     },
  //   }));
  // }, [ report]);
  
// console.log(selectedValues)

  
  const handleChangeConverter = (changedValues) => {
    setConverterId(changedValues);
    // setSelectedMeterIds();
    setSubmitClicked(false);
    if (changedValues) {
      setConverterError("");
    }
  };

  const handleMeterChange = (selectedValues) => {
    setSelectedMeterIds(selectedValues);
    setSubmitClicked(false);
    if (selectedValues) {
      setMeterError("");
    }
  };

  const handleTreeChange = (values) => {
    setSelectedValues(values);
    setSubmitClicked(false);
    if (values) {
      setParaError("");
    }
  };

  const setChartInputData = () => {
    localStorage.setItem("ConverterID", Number(converterId || 3));
    // localStorage.setItem("meterIds", Number( selectedMeterIds || 3));
    localStorage.setItem("meterIds", JSON.stringify(selectedMeterIds || ["1"]));

    // const converterId = Number(converterId || 3);
    // const selectedMeterIds = selectedMeterIds || ["1"];

    // // Set ConverterID and meterIds in localStorage
    // localStorage.setItem("ConverterID", converterId);
    // localStorage.setItem("meterIds", JSON.stringify(selectedMeterIds));

    const currentDate = new Date();
    // const currentDate = new Date().toLocaleString();
    console.log(currentDate)
    // calculateDateRangeFromDate(currentDate, 15, 2);
    const { from, to } = calculateDateRangeFromDate(currentDate, 15, 2);

    const lgConvId = localStorage.getItem("ConverterID");
    const meterId = localStorage.getItem("meterIds");
    const inputIds = JSON.parse(meterId)?.map((item) => item);

    console.log(meterId)
    console.log(inputIds[0])
    const meters=inputIds[0]

    // console.log('first')
    console.log(from,to)
    console.log(lgConvId,meterId)

    // const inputIds = JSON.parse(meterId).map((item) => item);
    // const ids = inputIds?.map((id) => id).join(",");

    const inputData = {
      ConverterID: Number(lgConvId),
      MeterIds: meters,
      FromDate: from, 
      ToDate:to,
    }; 

    // const queryString = (inputData);
    setChartInput(inputData);
    // getReposnse()
    
  };


  // useEffect(() => {
  //   setChartSkip(false);
  //   updateChart();
  //   const interval = setInterval(() => {
  //     refetchChart();
  //     updateChart();
  //   }, [1000 * 60]);

  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, [chartInput, steamConverterLiveChartData, selectedValues]);

  // useEffect(() => {
  //   if (!isSteamConverterLiveChartLoading) {
  //     updateChart();
  //   }
  // }, [isSteamConverterLiveChartLoading]);

  // useEffect(() => {
  //   setChartData((prevChartData) => ({
  //     series: seriesChartData || [],
  //     options: {
  //       ...prevChartData.options,
  //       xaxis: {
  //         ...prevChartData.options.xaxis,
  //         categories: chartTime ? chartTime.reverse() : [],
  //       },
  //       yaxis: {
  //         title: {
  //           text: selectedValues.join(",".toString()),
  //         },
  //       },
  //     },
  //   }));
  // }, [seriesChartData, chartTime]);

  // const parameters = [
  //   { id: 1, name: "flowRate" },
  //   { id: 2, name: "cumulative" },
  // ];

  const isInvalid = singleMeterName?.split("-");

  const validateInput = (value, label) => {
    const error = !value ? `Please Select ${label}` : "";
    return error;
  };

  const handleSubmit = () => {
    const converterError = validateInput(converterId, "Converter");
    const meterError = validateInput(selectedMeterIds, "Meter");

    setConverterError(converterError);
    setMeterError(meterError); 

    console.log(converterList);
    console.log(meterList);


    if ( !converterError && !meterError) {
      setSubmitClicked(true);
      // setChartInputData();
      getReposnse();
      onClose(); 
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      if (
        submitClicked &&
        converterId &&
        selectedMeterIds?.length &&
        selectedValues?.length
      ) {
        // setChartInputData();
        getReposnse()
      }
    }, 10000);
    return () => clearInterval(timer);
  }, [
    converterId,
    selectedMeterIds,
    submitClicked,
    selectedValues,
  ]);



  function transformArray(arr) {
    const result = [];

    const contains1 = arr.includes(1);
    const contains2 = arr.includes(2);
    if (contains1 && contains2) {
        result.push("temperature", "rh");
    } else {
        for (let i = 0; i < arr.length; i++) {
            if (arr[i] === 1) {
                result.push("temperature");
            } else if (arr[i] === 2) {
                result.push("rh");
            }
        }
    }

    return result;
}
  const updateChart = async () => {
    const meterId = localStorage.getItem("meterIds");
    // const meterId = selectedMeterIds?.map((id) => id).join(",");
    // console.log(meterId)
    const inputIds = JSON.parse(meterId)?.map((item) => item);

  // console.log(inputIds);

  // console.log(report)
    // filter data based on selected meters 
    const filterMeterData = () => { 
      const selectedMeterData = report?.filter((item) =>
        inputIds?.map((id) => Number(id)).includes(item?.meterid)
      );
      // console.log(selectedMeterData)
      return selectedMeterData;
    };

    // transform the data in a chart series format
    const transformData = (property, meterName) => { 
      // console.log(meterName) 
     
      // console.log(property.toLowerCase())
      // console.log(filterMeterData())
      const data = filterMeterData()
        ?.filter((item) => item?.metername === meterName) 
        ?.map((item) => item[property]);
      data?.reverse();

      const seriesData = {
        name: `${property}-${meterName}`, // Concatenate property and METERNAME
        // name: `${meterName}`, // Concatenate property and METERNAME
        data,
      }; 
      // console.log(data)

      return seriesData;
    };
    // { parameterid: 1, parametername: 'Temperature' },
    // { parameterid: 2, parametername: 'RH' }
 
  
  // // Example usage
  // const array = [1, 2, 1, 2];
  // const transformedArray = transformArray(array);
  // console.log(transformedArray);  // Output: ["Temperature", "RH"]
  

    const getChartSeriesData = () => { 
      // console.log(selectedValues)
      const ans = transformArray(selectedValues)
      // console.log(ans)
      // const properties = selectedValues?.length ? selectedValues : ["Temperature"];
      // console.log(properties);
      const seriesDataArray = [];

      // Iterate over selected meter IDs and properties
      inputIds?.forEach((id) => {
        const meterName = filterMeterData()?.find(
          (item) => item?.meterid === Number(id)
        )?.metername; 
        // console.log(meterName)
 
        ans.forEach((property) => {
          seriesDataArray.push(transformData(property, meterName));
        }); 
        // properties.forEach((property) => {
        //   seriesDataArray.push(transformData(property, meterName));
        // }); 
      });
      // console.log(seriesDataArray)
      return seriesDataArray; 
    };
 

    const getChartDate = () => {
      const dateArray = [];
      const date = ["createdON"];
      inputIds?.forEach((id) => {
        const meterName = filterMeterData()?.find(
          (item) => item?.meterid === Number(id)
        )?.metername;

        date?.forEach((property) => {
          dateArray.push(transformData(property, meterName));
        });  
      });
      // Find the array with the maximum length
      const longestArray = dateArray.reduce((maxArray, currentArray) => {
        return currentArray?.data?.length > maxArray?.data?.length
          ? currentArray
          : maxArray;
      }, dateArray[0] || []);
      return longestArray?.data?.reverse();
    };

    const finalSeriesData = () => {
      const firstOption = selectedValues?.includes("Temperature");  
      const secondOption = selectedValues?.includes("RH");
      // console.log(getChartSeriesData())
 
      const finalData = getChartSeriesData()?.filter((series) => {
        if (firstOption && secondOption) { 
          // Both options selected, keep all series
          return true;
        } else if (firstOption) {
          // First option selected, add series with "FlowRate" 
          return series.name.includes("Temperature"); 
        } else if (secondOption) { 
          // Second option selected, add series with "Cumulative"
          return series.name.includes("RH");
        }
        // If neither option selected, keep all series  
        return true;
      });
      return finalData;
    };
 
    const reversedDate = getChartDate(); 
    const localDateTime = reversedDate?.map((date) =>
      new Date(date).toLocaleString()
    );

    const finalSeries = finalSeriesData();
    // console.log(finalSeries)
    setSingleMeterName(finalSeries[0]?.name);
    // console.log(localDateTime)

    setSeriesChartData(finalSeries);
    setChartTime(localDateTime);
  };

  useEffect(() => { 
    updateChart();
    const interval = setInterval(() => {
      updateChart();  
    }, [1000 * 60]);

    return () => {
      clearInterval(interval); 
    };
  }, [chartInput, selectedValues,report]);
    

  useEffect(() => {
    setChartData((prevChartData) => ({
      series: seriesChartData || [],
      options: {
        ...prevChartData.options, 
        xaxis: {
          ...prevChartData.options.xaxis,
          categories: chartTime ? chartTime.reverse() : [],
        },
        yaxis: {
          title: {
            text: transformArray(selectedValues).join(",".toString()),
          },
        },
      },
    }));
  }, [seriesChartData, chartTime,report]);


  // console.log('chartData')
  // console.log(chartData)

  return (
    <div id="chart" className="bg-white p-4 rounded-md">
      {/* {!isSteamConverterLiveChartLoading && !isSteamConverterLiveChartError && ( */}
        <>
          <ReactApexChart
            options={chartData.options}
            series={chartData.series}
            type="line"
            height={600}
            className="w-full"
          />
          {seriesChartData?.length === 1 && isInvalid[1] !== "undefined" && (
            <p className="text-center ml-16">{singleMeterName}</p>
          )}
        </>
      {/* )} */}
      {/* {isSteamConverterLiveChartLoading && ( */}
        {/* <>
          <Skeleton active paragraph={{ rows: 6 }} />
        </> */}
      {/* )} */}
      {/* <div className="flex justify-center">
          <div className="flex flex-col">
            <img className="w-[150px] h-[80px] " src={errorNew} />
            <p className="text-sm text-red-500 text-center">Server error</p>
            <p className="text-center">Please reload the page</p>
          </div>
      </div> */}
      <CustomDrawer
        title="Close"
        showDrawer={showDrawer}
        open={open}
        onClose={onClose}
      >
        <div className="flex flex-col space-y-2">
          <CustomSelect
            label="Converter:"
            options={ converterList}
            onSelect={handleChangeConverter}
            valueKey={"sitE_ID" }
            nameKey={"sitename"}
            // valueKey={sectionId === 1 ? "sitE_ID" : "groupID"}
            // nameKey={sectionId === 1 ? "sitename" : "groupName"}
          />
          <small className="text-red-500 font-bold ml-[150px]">
            {converterError}
          </small>
          <TreeSelectCustomWithSelectAll
            label="Meters:"
            data={meterList}
            idKey={"meteR_METERID"}
            valueKey={"meteR_METERID"}
            nameKey={"metername"}
            selectedValues={selectedMeterIds}
            setSelectedValues={setSelectedMeterIds}
            onSelect={handleMeterChange}
          />

          <small className="text-red-500 font-bold ml-[150px]">
            {meterError}
          </small>

          <TreeSelectCustom
            label="Parameters:"
            data={parameter}
            idKey="id"
            nameKey="parametername"
            valueKey="parameterid"
            selectedValues={selectedValues}
            setSelectedValues={setSelectedValues}
            onSelect={handleTreeChange}
          />
          <small className="text-red-500 font-bold ml-[150px]">
            {paraError}
          </small>
        </div>
        <div className="flex justify-center mt-4">
          <Button
            className="blue-btn"
            onClick={() => {
              handleSubmit();
            }}
          >
            Search
          </Button>
        </div>
      </CustomDrawer>
    </div>
  );
};

export default LiveChart;
