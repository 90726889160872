import React, { useEffect, useState } from "react";
import { Button, Card, DatePicker, Select, Skeleton } from "antd";
import MeterGrid from "./MeterGrid";
import TreeSelectCustom from "../LiveTrends/LineChart/TreeSelectCustom";
import { calculateDateRangeFromDate } from "../../../../../../Utils/DateConverstion";
import CustomDrawer from "../../../../../Elements/CustomDrawer";
import CustomSelect from "../../../../../Elements/CustomSelect";
import TreeSelectCustomWithSelectAll from "../LiveTrends/LineChart/TreeSelectCustomWithAllSelect";
import { useSelector } from "react-redux";
import { useStoreUpdaterWithAPI } from "../../../../../../Hooks/UseStoreUpdaterWithApi";
import { APIConstants } from "../../../../../../Common/APIConstants";
// import errorNew from "../../../../"


const { Option } = Select;
const { RangePicker } = DatePicker;

const MeterStatus = () => {  
  const token = useSelector((state) => state.user.token)
  const { loading, makeRequest } = useStoreUpdaterWithAPI();
  const [open, setOpen] = useState(false);
  const [sectionId, setSectionId] = useState(null);
  const [converterGroupId, setConverterGroupId] = useState(null);
  const [report, setReport] = useState(null);
  const [skip, setSkip] = useState(true);
  const [selectedValues, setSelectedValues] = useState([]);
  const [selectedMeterIds, setSelectedMeterIds] = useState([]);
  const [chartSkip, setChartSkip] = useState(true);

  const [sectionError, setSectionError] = useState();
  const [converterError, setConverterError] = useState();
  const [meterError, setMeterError] = useState();

  const [submitClicked, setSubmitClicked] = useState(false);
  const [converterList,setConverterList] = useState([])
  const [meterList,setMeterList] = useState([]);

  const [input, setInput] = useState(null);
  const [error,setError] =  useState(false);

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

//   const {
//     data: flowSectionResData,
//     isLoading: isFlowSectionLoading,
//     isError: isFlowSectionError,
//     error: flowError,
//     refetch: flowRefetch,
//   } = useGetFlowSectionMasterDataQuery();

//   const {
//     data: getFlowConverterResData,
//     isLoading: isFlowConverterLoading,
//     isError: isFlowConverterError,
//     error: flowConverterError,
//   } = useGetFlowConverterDetailsQuery();

//   const {
//     data: flowGroupDetailsResData,
//     isLoading: isFlowGroupListLoading,
//     isError: isFlowGroupListError,
//     error: flowGroupListError,
//     refetch: flowGroupRefetch,
//   } = useGetFlowGroupDetailsQuery(undefined, { skip });

//   const {
//     data: nsGetFlowConverterResData,
//     isLoading: isNSgetFlowConverterLoading,
//     isError: isnsgetFlowConverterError,
//     error: nsgetFlowConverterError,
//   } = useNSGetFlowConverterDetailsQuery(objectToQueryString({ ConverterID: converterGroupId || 1  }), { skip });

//   const {
//     data: getFlowSteamGroupMeterResData,
//     isLoading: isFlowSteamGroupLoading,
//     isError: isFlowSteamGroupError,
//     error: FlowSteamGroupError,
//   } = useGetFlowSteamGropeMeterDetailsQuery(objectToQueryString({GroupID : converterGroupId || 1}) , {
//     skip,
//   });

  const inputData = {
    SectionID:  1,
    ConverterorGroupID: 1,
    MeterIds:  "1",
    FromDate:  "2023-09-21T07:15:00.000Z",
    ToDate:  "2023-09-25T07:15:00.000Z",
  }

//   const {
//     data: steamMeterData,
//     isLoading: isSteamMeterStatusLoading,
//     isError: isSteamMeterStatusError,
//     error: steamMeterStatusError,
//   } = useGetsteamMeterStatusDetailsQuery(input || objectToQueryString(inputData), { chartSkip });


//   const getFlowSectionList = (flowSectionResData && flowSectionResData) || [];
//   const getFlowConverterList =
//     (getFlowConverterResData && getFlowConverterResData) || [];
//   const flowGroupDetailsList =
//     (flowGroupDetailsResData && flowGroupDetailsResData) || [];
//   const nsGetFlowConverterList =
//     (nsGetFlowConverterResData && nsGetFlowConverterResData) || [];
//   const getFlowSteamGroupMeterList =
//     (getFlowSteamGroupMeterResData && getFlowSteamGroupMeterResData) || [];

  // const handleChangeSection = (changedValues) => {
  //   setReport([])
  //   setSectionId(changedValues);
  //   setSelectedMeterIds();
  //   setConverterGroupId(null);
  //   setSubmitClicked(false)
  //   if (changedValues) {
  //     setSectionError("");
  //   }
  // };

  useEffect(() => {
    // After 1 second, set skipValue to false
    const timeoutId = setTimeout(() => {
      setSkip(false);
    }, 3000);

    // // Clear the timeout if the component unmounts or skipValue is set to false
    return () => clearTimeout(timeoutId);
  }, []);


  useEffect(() => {
    // for converters 
    makeRequest({
      type: APIConstants.GET,
      url: APIConstants.GET_CONVERTER,
      token:token
    }).then((res) => {
      // console.log('converter')
      console.log(res)
      setConverterList(res)
    });

  },[]);

  useEffect(() => {

    // for meters 
    makeRequest({
      type: APIConstants.GET,
      url: APIConstants.GET_CONVERTER_METERS +`${converterGroupId}`,
      token:token
    }).then((res) => {
      // console.log('meter')
      // console.log(res)
      setMeterList(res)
    });

  },[converterGroupId]);

  const getReposnse =()=>{
    console.log('getReposnse')
    // console.log(chartInput);
    console.log(input)
    try {
      makeRequest({
        type: APIConstants.GET,
        url: APIConstants.METERSTATUS +`ConverterorGroupID=${input.ConverterorGroupID}&MeterIds=${input.MeterIds}&FromDate=${input.FromDate}&ToDate=${input.ToDate}`,
        token:token,
        // body:chartInput
      }).then((res) => {
        console.log('response')
        console.log(res)
        setReport(res)
      });
      
    } catch (error) {
      setError(error)
    }
  }

  

  const handleChangeConverterGroup = (changedValues) => {
    setReport([])
    setConverterGroupId(changedValues);
    setSubmitClicked(false)
    // setSelectedMeterIds();
    if (changedValues) {
      setConverterError("");
    }
  };

  const handleMeterChange = (selectedValues) => {
    setSelectedMeterIds(selectedValues);
    if(!selectedValues.length){
      setSubmitClicked(false)
    }
    if (selectedValues) {
      setMeterError("");
    }
  };

  const setInputData = () => {
    const ids = selectedMeterIds?.map((id) => id).join(",");
    const currentDate = new Date();
    const { from, to } = calculateDateRangeFromDate(currentDate, 5, 1);

    const inputData = {
      // SectionID: Number(sectionId),
      ConverterorGroupID: Number(converterGroupId) ,
      MeterIds: ids,
      FromDate:  from,
      ToDate:  to,
    };

    // const queryString = objectToQueryString(inputData);
    setInput(inputData);
  };

  const validateInput = (value, label) => {
    const error = !value ? `Please Select ${label}` : "";
    return error;
  };
  
  const handleSubmit = async () => {

    setSubmitClicked(true)
    // const sectionError = validateInput(sectionId, "Section");
    const converterError = validateInput(converterGroupId, "Converter");
    const meterError = validateInput(selectedMeterIds?.length, "Meter");

    // setSectionError(sectionError);
    setConverterError(converterError);
    setMeterError(meterError);


    const currentDate = new Date();
    const { from, to } = calculateDateRangeFromDate(currentDate, 5, 1);

    console.log(selectedMeterIds)
    const ids = selectedMeterIds?.map((id) => id).join(",");
    console.log(ids)
    const inputData = {
      // SectionID: Number(sectionId),
      ConverterorGroupID: Number(converterGroupId) ,
      MeterIds: ids,
      FromDate:  from,
      ToDate:  to,
    };

    try {
      makeRequest({
        type: APIConstants.GET,
        url: APIConstants.METERSTATUS +`ConverterorGroupID=${inputData.ConverterorGroupID}&MeterIds=${inputData.MeterIds}&FromDate=${inputData.FromDate}&ToDate=${inputData.ToDate}`,
        token:token,
        // body:chartInput
      }).then((res) => {
        console.log('response')
        console.log(res)
        setReport(res)
      });
      
    } catch (error) {
      setError(error)
    }

    if (!converterError && !meterError ) {
      onClose()
    }
  };

//   useEffect(() => {
//   if (steamMeterData?.length) {
//       const selectedMeterData = steamMeterData?.filter((item) =>
//         selectedMeterIds?.map((id) => Number(id)).includes(item.meterid)
//       );
//       setReport(selectedMeterData);
//     }
//   }, [steamMeterData]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (submitClicked && converterGroupId && sectionId && selectedMeterIds?.length ) {
        setInputData();
      }
    },10000)
    return () => clearInterval(timer);
  },[converterGroupId, sectionId, selectedMeterIds, submitClicked])

 
  
  console.log(report)
  return (
    <div>
      <p className="pb-2 text-xl ml-3">METER STATUS</p>
      
      {/* {isSteamMeterStatusLoading && ( */}
        {/* <div>
          <Skeleton active paragraph={{ rows: 6 }} />
        </div> */}
      {/* )} */}
      <div className="flex justify-center">
        {!report && error && (
          <div className="flex flex-col">
            {/* <img className="w-[150px] h-[80px] " src={errorNew} /> */}
            <p className="text-sm text-red-500 text-center">Server error</p>
            <p className="text-center">Please reload the page</p>
          </div>
        )}
      </div>
      {/* {!isSteamMeterStatusLoading  && ( */}
        <MeterGrid report={report} />
      {/* )} */}
      <CustomDrawer
        title="Close"
        showDrawer={showDrawer}
        open={open}
        onClose={onClose}
      >
        <div className="flex flex-col space-y-2">
          {/* <CustomSelect
            label="Section:"
            // options={getFlowSectionList}
            onSelect={handleChangeSection}
            valueKey="sectionID"
            nameKey="sectionName"
          />
          <small className="text-red-500 font-bold ml-[150px]">{sectionError}</small> */}
          <CustomSelect
            label="Converter:"
            options={converterList}
            onSelect={handleChangeConverterGroup}
            valueKey={"sitE_ID" }
            nameKey={"sitename"}
          />
          <small className="text-red-500 font-bold ml-[150px]">{converterError}</small>
          {/* <CustomSelect */}
          <TreeSelectCustomWithSelectAll
            label="Meters:"
            data={ meterList}
            // idKey={sectionId === 1 ? "meterid" : "meterID"}
            valueKey={"meteR_METERID"}
            nameKey={"metername"}
            selectedValues={selectedMeterIds}
            setSelectedValues={setSelectedMeterIds}
            onSelect={handleMeterChange}
          />
         
          <small className="text-red-500 font-bold ml-[150px]">{meterError}</small>
          {/* <TreeSelectCustom
            label="Parameters:"
            data={parameters}
            idKey="id"
            nameKey="name"
            valueKey="name"
            selectedValues={selectedValues}
            setSelectedValues={setSelectedValues}
            onSelect={handleTreeChange}
          />
          <small className="text-red-500 font-bold ml-[150px]">
            {paraError}
          </small> */}
          
        </div>
        <div className="flex justify-center mt-4">
          <Button
            className="blue-btn"
            onClick={() => {
              handleSubmit();
            }}
          >
            Search
          </Button>
        </div>
      </CustomDrawer>
    </div>
  );
};

export default MeterStatus;
