import React, { useState } from "react";
import { roundOffValue, roundToTwoDecimalPlaces } from "../../../../../../Utils/DateConverstion";
import { Button, Modal,  } from "antd";
import "./modal.css"
import { useEffect } from "react";

const MeterGrid = ({ report }) => {
  const [selectedReport, setSelectedReport] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = (item) => {
    setSelectedReport(item);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setSelectedReport(null);
    setIsModalVisible(false);
  };
  useEffect(() => {
    console.log(report)
  }, [])

  // {
  //   meterid: 3,
  //   metername: 'salcomp',
  //   sitename: 'SalCompCN2',
  //   temperature: 0,
  //   rh: 55,
  //   createdON: '2024-06-21T19:05:00'
  // },
  

  return (
    <div className={`flex px-2 py-2 flex-wrap ${report !== null ? "bg-white" : ""} rounded-md`}>
      {report?.length < 1 && <p>No Data</p>}

      {report?.map((item) => (
        <div key={item?.meterid} onClick={() => showModal(item)} className="w-52 h-50 my-1 mx-1 rounded-md ant-modal-card border-2 text-center divide-y-2 border-green-600" style={{borderColor: item.meterStatus==='OFF'? "red" : item.meterStatus==='NO'?"green": "yellow"}}>
        {/* <div key={item?.meterid} onClick={() => showModal(item)} className="w-52 h-50 my-1 mx-1 rounded-md ant-modal-card border-2 text-center divide-y-2 border-green-600" style={{borderColor: item.cumulative && !item.flowRate ? "yellow" : item.flowRate && item.cumulative ? "green" : "red",}}> */}
{/* 
        {
      meterid: 3,
      metername: 'salcomp',
      sitename: 'SalCompCN2',
      temperature: 26,
      rh: 43,
      meterStatus: 'NO',
      createdON: '2024-06-24T13:44:00'
    } */}


          <p className="py-2 h-auto font-bold text-white" style={{backgroundColor: item.meterStatus==='OFF'? "red" : item.meterStatus==='NO'?"green": "yellow",color:'black'}}>
            {item?.metername}
          </p>
{/* 
          <p className="py-2 h-auto font-bold text-white" style={{ backgroundColor: item.cumulative && !item.flowRate ? "yellow" : item.flowRate && item.cumulative ? "green" : "red", color: item.cumulative && !item.flowRate ? "black" : " #fff" }}>
            {item?.metername} 
          </p> */}
          <div className="space-y-3 h -auto font-bold text-black">
            <p className="border-b-2 my-3 h-auto  pb-2" style={{borderColor: item.meterStatus==='OFF'? "red" : item.meterStatus==='NO'?"green": "yellow"}}>
              Temperature: {item?.temperature}
            </p>
            <p className="h-auto pb-4">
              Rh: {item?.rh}
            </p>
          </div>
        </div>
      ))}

      <Modal
        open={isModalVisible} 
        onCancel={handleCancel}
        closable={false}
        className="custom-modal"
        footer={[
          <Button className="mr-5 mb-5" key="back" onClick={handleCancel}>
            Cancel
          </Button>,    
        ]}
        style={{ padding: 0 }} 
        title={
        <div style={{backgroundColor: selectedReport?.meterStatus==='OFF'? "red" : selectedReport?.meterStatus==='NO'?"green": "yellow",color: 'white',padding:5, borderRadius:"8px 8px 0 0"}}>Last poll time</div>
        // <div style={{ backgroundColor: 'green',  color: 'white',padding:5, borderRadius:"8px 8px 0 0" }}>Last poll time</div>
      }
      >
          
          <div style={{padding:5}} className="text-center">
            {selectedReport?.createdON !== "0001-01-01T00:00:00" ? new Date(selectedReport?.createdON).toLocaleString() : "N/A"}
          </div>
       
      </Modal>
    </div>
  );
};

export default MeterGrid;




