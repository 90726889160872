import "./App.css";
import { Route, Routes, Navigate } from "react-router";
import { App } from "antd";
import { HashRouter } from "react-router-dom";

import { LOGIN, UNAUTHORIZED_PATH, AR_NO_MATCH } from "./Common/Constants";
import LoginForm from "./components/CommonScreens/LoginForm";
import TemperatureLayout from "./components/CommonScreens/TemperatureLayout";
import LiveChart from "./components/Screens/Electrical/Charts/LiveModule/LiveTrends/LineChart/LiveChart";
import MeterStatus from "./components/Screens/Electrical/Charts/LiveModule/MeterStatus/MeterStatus";
import MeterConfig from "./components/Screens/Electrical/Charts/Configuration/MeterConfig/MeterConfig";
import SiteConfig from "./components/Screens/Electrical/Charts/Configuration/SiteConfig/SiteConfig";
import ProtectedRoutes from "./Utils/ProtectedRoutes";
import RawData from "./components/Screens/Electrical/Charts/Configuration/RawData/RawDataGrid/RawData";



export default function Router() {
  return (
    <>
      <App>
        <HashRouter>
          <Routes>
            <Route path={LOGIN} element={<LoginForm />} />

            <Route path="/" element={<ProtectedRoutes/>}>
                <Route path="temperature-dashboard/*" element={<TemperatureLayout />}>
                  <Route path="live-trends" element={<LiveChart />} />
                  <Route path="meter-status" element={<MeterStatus />} />

                  <Route path="meter-config" element={<MeterConfig />} />
                  <Route path="site-config" element={<SiteConfig/>} />
                  <Route path="raw-config" element={<RawData/>} />
                </Route>
            </Route>


            <Route path={AR_NO_MATCH} element={<Navigate to={LOGIN} />} />
            <Route path={UNAUTHORIZED_PATH} element={<Navigate to={LOGIN} />} />
          </Routes>
        </HashRouter>
      </App>
    </>
  );
}
