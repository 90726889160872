import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loggedIn: false,
  token:''
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logIn: (state,action) => {
      state.loggedIn = true,
      state.token = action.payload
    },
    logOut: (state) => {
      state.loggedIn =false
    },
  },
})

// Action creators are generated for each case reducer function
export const { logIn, logOut } = userSlice.actions

export default userSlice.reducer