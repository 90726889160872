
import { createContext, useContext, useReducer } from "react";

const CacheContext = createContext(null);
const CacheDispatchContext = createContext(null);

export const CacheProvider = ({ children }) => {
  const cacheReducer = (cache, action) => {
    switch (action.type) {
      case "add": {
        const newCache = new Map(cache);
        newCache.set(action.key, action.value);
        return newCache;
      }
      case "clear": {
        return new Map();
      }
      default: {
        throw Error("Unknown action: " + action.type);
      }
    }
  };

  const initialCache = new Map();

  const [apiResponseMap, dispatch] = useReducer(cacheReducer, initialCache);

  return (
    <CacheContext.Provider value={apiResponseMap}>
      <CacheDispatchContext.Provider value={dispatch}>{children}</CacheDispatchContext.Provider>
    </CacheContext.Provider>
  );
};

export const useCache = () => {
  return useContext(CacheContext);
};

export const useCacheDispatch = () => {
  return useContext(CacheDispatchContext);
};
