

export const APP_ENV = process.env.REACT_APP_ENVIRONMENT;
export const APP_VERSION = process.env.REACT_APP_VERSION;


export const TVS_ACCESS_TOKEN = "TVS_A_T";

//App routes
export const LOGIN = "/login";
export const LANDING = "/landing-page";
export const ERROR = LOGIN + "/error";
export const TEMPERATURE_DASHBOARD =  "/temperature-dashboard";








/////////////////
export const UNAUTHORIZED_PATH =  "/unauthorized-access";
export const AR_NO_MATCH = "*";


export const Messages = {
    LOADING: "Loading...",
   FETCH_DATA: "data fetched successfully",
   SAVE_DATA:"data saved successfully",
   UPDATE_DATA: "data updated successfully",
   DELETE_DATA:"data deleted successfully"

  };






