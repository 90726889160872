/* eslint-disable no-unused-vars */
import { Form, Input, Button, ConfigProvider } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import loginImage from "../../Assets/Images/login2.png";
import minnotra_logo from "../../Assets/Images/logo2.png";
import { useNavigate } from "react-router-dom";
import { APIConstants }from './../../Common/APIConstants'
import { useStoreUpdaterWithAPI } from "../../Hooks/UseStoreUpdaterWithApi";
import { useDispatch, useSelector } from "react-redux";
import { logIn } from "../../Store/Reducers/UserReducer";

const LoginForm = () => {
  const [userData, setUserData] = useState({});
  const { loading, makeRequest } = useStoreUpdaterWithAPI();
  const userLoggedIn = useSelector((state) => state.user.loggedIn)
  const navigate = useNavigate();
  const dispatch = useDispatch()

  console.log(userLoggedIn)
  const [form] = Form.useForm();
  const handleInputChange = (changedValues, allValues) => {
    console.log( allValues)
    setUserData(allValues);
  };

  const handleReset = () => {
    form.resetFields();
  };

// //   const [productLogin, { isLoading, isError, error }] = useProductLoginMutation();

// useEffect(() => {

//   makeRequest({
//     type: APIConstants.GET,
//     url: APIConstants.GET_APPLICATION_MODULE_DETAILS,
//   }).then((res) => {
//     setGroupModuleList(res);
//     console.log(res)
//   });

// },[]);


  const onSubmit = async () => {
    try {
      console.log(APIConstants.LOGIN_API)
      // console.log(userData)
      makeRequest({
        type: APIConstants.POST,
        url: APIConstants.LOGIN_API,
        body:userData
      }).then((res) => {
        console.log(res)
        dispatch(logIn(res?.token))
        res?.token ? navigate("/temperature-dashboard"): navigate("/login")
      });
      // const queryParams = objectToQueryString(userData);

      // const res = await productLogin(queryParams).then(()=>{
      //   navigate("/landing-page");
      // })

      // userLoggedIn ? navigate("/temperature-dashboard"): navigate("/login")
      // const token = res?.data?.accessToken;
      // if (token) {
      //   setCookie(token);
      //   navigate("/landing-page");
      // }else{
      //   return <p>Login error</p>
      // }

      handleReset();
    } catch (err) {
      console.error("Error in login:", err?.status);
    }
  };

  return (
    <>
      <div className="flex flex-col md:h-screen md:flex-row">
        <div className="sm:w-1/4  md:w-3/4 hidden md:block">
          <img
            src={loginImage}
            alt="Background"
            className="h-full w-full object-cover"
          />
        </div>
        <div className="md:w-2/4 lg:w-2/4 flex justify-center items-center">
          <div className="form-container">
            <div className="flex justify-center items-center">
              <img
                className="w-40 h-full mt-6 mb-20 lg:mt-0"
                src={minnotra_logo}
                alt="minnotra_logo"
              />
            </div>
            <div className="mb-10">
              <div className="text-lime-500 font-semibold text-center text-4xl">
                <p>USER</p>
                <p className="mb-4">LOGIN</p>
              </div>
              <p className="text-zinc-500 text-md tracking-wide font-semibold text-center">
                Welcome to the Expert Trax
              </p>
            </div>
            <ConfigProvider
              theme={{
                token: {
                  colorPrimary: "#7abd37",
                  colorBgContainer: "#f6ffed",
                },
              }}
             > 
              <Form
                name="normal_login"
                className="login-form"
                initialValues={{
                  remember: true,
                }}
                onFinish={onSubmit}
                form={form}
                onValuesChange={handleInputChange}
              >
                <Form.Item
                  name="username"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Username!",
                    },
                  ]}
                >
                  <Input
                    placeholder="username"
                    className="p-3 rounded-full"
                    prefix={
                      <UserOutlined className="site-form-item-icon pl-2" />
                    }
                  />
                </Form.Item>

                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Password!",
                    },
                  ]}
                >
                  <Input
                    prefix={
                      <LockOutlined className="site-form-item-icon pl-2" />
                    }
                    type="password"
                    placeholder="Password"
                    className="p-3 w-[300px] rounded-full"
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    // loading={isLoading}
                    // disabled={isLoading}
                    type="primary"
                    htmlType="submit"
                    className="login-form-button w-full border-0 rounded-full h-11 text-xl tracking-wide"
                    // style={{
                    //   color: isLoading ? "lightgray" : "white",
                    //   background: isLoading ? "gray" : "#7abd37",
                    // }}
                  >
                    LOGIN
                  </Button>
                </Form.Item>
              </Form>
              <div className="text-center text-red-400 font-semibold text-sm">
                {/* {isError && error?.status} */}
              </div>
            </ConfigProvider>
          </div>
        </div>
      </div>
    </>

    
  );
};

export default LoginForm;
