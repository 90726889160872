import React, { useEffect, useState } from "react";
import { Select, Button } from "antd";

const { Option } = Select;

const CustomSelect = (props) => {
  const { label, options, onSelect, valueKey, nameKey,mode, error } = props;

  // console.log(options,valueKey,nameKey);
  const [selectedValue, setSelectedValue] = useState(null);

  useEffect(() => {
    // Reset the selected value when the options change
    setSelectedValue(null);
  }, [options]);
  
  return (
    <>
      <div className="flex items-center">
        <label className="pr-3 w-32 text-right">{label}</label>
        <Select
          placeholder={`Select ${label}`}
          className="w-80"
          onChange={(value) => {
            setSelectedValue(value);
            onSelect(value);
          }}
          mode={mode}
          value={selectedValue}
        >
          {options?.map((option) => (
            <Option key={option[valueKey]} value={option[valueKey]}>
              {option[nameKey]}
            </Option>
          ))}
        </Select>
        {error && <Alert message={`Please select a ${label}`} type="error" />}
      </div>
    </>
  );
};

export default CustomSelect;
