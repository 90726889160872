


// import React, { useEffect, useState } from "react";
// import {
//   DatePicker,
//   Button,
//   Skeleton,
// } from "antd";
// import RawGrid from "./Grid";
// import CustomDrawer from "../../../../../../Elements/CustomDrawer";
// import CustomSelect from "../../../../../../Elements/CustomSelect";
// import TreeSelectCustomWithSelectAll from "../../../../../../Elements/TreeSelectCustomWithAllSelect";
// import { generatedISOFormatDateString } from "../../../../../../../Utils/DateConverstion";
// import { useStoreUpdaterWithAPI } from "../../../../../../../Hooks/UseStoreUpdaterWithApi";
// import { APIConstants } from "../../../../../../../Common/APIConstants";
// import { useSelector } from "react-redux";

// const RawData = () => {
//   const { loading, makeRequest } = useStoreUpdaterWithAPI();
//   const [converterGroupId, setConverterGroupId] = useState(null);
//   const [selectedMeterIds, setSelectedMeterIds] = useState([]);
//   const [selectedValues, setSelectedValues] = useState([]);
//   const [fromDateObj, setFromDateObj] = useState(null);
//   const [fromDateString, setFromDateString] = useState();
//   const [toDateObj, setToDateObj] = useState(null);
//   const [toDateString, setToDateString] = useState();
//   const [open, setOpen] = useState(false);
//   const [report, setReport] = useState(null);
//   const [converterList, setConverterList] = useState([]);
//   const [meterList, setMeterList] = useState([]);
//   const token = useSelector((state) => state.user.token);

//   const showDrawer = () => {
//     setOpen(true);
//   };

//   const onClose = () => {
//     setOpen(false);
//   };

//   useEffect(() => {
//     makeRequest({
//       type: APIConstants.GET,
//       url: APIConstants.GET_CONVERTER,
//       token: token,
//     }).then((res) => {
//       setConverterList(res);
//     });
//   }, []);

//   useEffect(() => {
//     if (converterGroupId) {
//       makeRequest({
//         type: APIConstants.GET,
//         url: APIConstants.GET_CONVERTER_METERS + `${converterGroupId}`,
//         token: token,
//       }).then((res) => {
//         setMeterList(res);
//       });
//     }
//   }, [converterGroupId]);

//   const handleChangeConverterGroup = (changedValues) => {
//     setConverterGroupId(changedValues);
//     setSelectedMeterIds([]);
//   };

//   const handleMeterChange = (selectedValues) => {
//     setSelectedMeterIds(selectedValues);
//   };

//   const onChangeFromDate = (date, dateString) => {
//     setFromDateString(dateString);
//     setFromDateObj(date);
//   };

//   const onChangeToDate = (date, dateString) => {
//     setToDateString(dateString);
//     setToDateObj(date);
//   };

//   const handleSubmit = () => {
//     const formattedFromDate = generatedISOFormatDateString(new Date(fromDateString));
//     const formattedToDate = generatedISOFormatDateString(new Date(toDateString));
//     const ids = selectedMeterIds.join(",");
//     const inputData = {
//       ConverterorGroupID: Number(converterGroupId),
//       MeterIds: ids,
//       FromDate: formattedFromDate,
//       ToDate: formattedToDate,
//     };

//     makeRequest({
//       type: APIConstants.GET,
//       url: `${APIConstants.RAW_DATA}?ConverterID=${inputData.ConverterorGroupID}&MeterIds=${inputData.MeterIds}&FromDate=${inputData.FromDate}&ToDate=${inputData.ToDate}`,
//       token: token,
//     }).then((res) => {
//       console.log(res)
//       setReport(res);
//     });
//     onClose();
//   };
//   // RawData?ConverterID=1&MeterIds=1&FromDate=2024-06-27%2018%3A00%3A00&ToDate=2024-06-28%2015%3A00%3A00

//   return (
//     <div className="bg-white rounded-md w-auto">
//       {loading && <Skeleton active paragraph={{ rows: 20 }} className="mt-12 mx-3" />}
//       <div className="m-2">
//         <RawGrid report={report} parameter={selectedValues.length === 2} />
//       </div>
//       <CustomDrawer title="Close" showDrawer={showDrawer} open={open} onClose={onClose}>
//         <div className="flex flex-col space-y-2">
//           <CustomSelect
//             label="Converter:"
//             options={converterList}
//             onSelect={handleChangeConverterGroup}
//             valueKey={"sitE_ID"}
//             nameKey={"sitename"}
//           />
//           <TreeSelectCustomWithSelectAll
//             label="Meters:"
//             data={meterList}
//             valueKey={"meteR_METERID"}
//             nameKey={"metername"}
//             selectedValues={selectedMeterIds}
//             setSelectedValues={setSelectedMeterIds}
//             onSelect={handleMeterChange}
//           />
//           <div className="mt-2 flex">
//             <label className="pr-3 w-32 text-right">From Date:</label>
//             <DatePicker
//               value={fromDateObj}
//               onChange={onChangeFromDate}
//               className="w-80"
//               showTime={{ format: "HH:mm:ss" }}
//               format="YYYY-MM-DD HH:mm:ss"
//             />
//           </div>
//           <div className="mt-2 flex">
//             <label className="pr-3 w-32 text-right">To Date:</label>
//             <DatePicker
//               value={toDateObj}
//               onChange={onChangeToDate}
//               className="w-80"
//               showTime={{ format: "HH:mm:ss" }}
//               format="YYYY-MM-DD HH:mm:ss"
//             />
//           </div>
//         </div>
//         <div className="flex justify-center mt-4">
//           <Button className="blue-btn" onClick={handleSubmit}>
//             Search
//           </Button>
//         </div>
//       </CustomDrawer>
//     </div>
//   );
// };

// export default RawData;



import React, { useEffect, useState } from "react";
import { DatePicker, Button, Skeleton } from "antd";
import RawGrid from "./Grid";
import CustomDrawer from "../../../../../../Elements/CustomDrawer";
import CustomSelect from "../../../../../../Elements/CustomSelect";
import { generatedISOFormatDateString } from "../../../../../../../Utils/DateConverstion";
import TreeSelectCustomWithSelectAll from "../../../../../../Elements/TreeSelectCustomWithAllSelect";
import { useStoreUpdaterWithAPI } from "../../../../../../../Hooks/UseStoreUpdaterWithApi";
import { APIConstants } from "../../../../../../../Common/APIConstants";
import { useSelector } from "react-redux";

const RawData = () => {
  const { loading, makeRequest } = useStoreUpdaterWithAPI();
  const [converterGroupId, setConverterGroupId] = useState(null);
  const [selectedMeterIds, setSelectedMeterIds] = useState([]);
  const [selectedMeterNames, setSelectedMeterNames] = useState([]);
  const [fromDateObj, setFromDateObj] = useState(null);
  const [fromDateString, setFromDateString] = useState();
  const [toDateObj, setToDateObj] = useState(null);
  const [toDateString, setToDateString] = useState();
  const [open, setOpen] = useState(false);
  const [report, setReport] = useState([]);
  const [converterList, setConverterList] = useState([]);
  const [meterList, setMeterList] = useState([]);
  const token = useSelector((state) => state.user.token);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    makeRequest({
      type: APIConstants.GET,
      url: APIConstants.GET_CONVERTER,
      token: token,
    }).then((res) => {
      setConverterList(res);
    });
  }, []);

  useEffect(() => {
    if (converterGroupId) {
      makeRequest({
        type: APIConstants.GET,
        url: APIConstants.GET_CONVERTER_METERS + `${converterGroupId}`,
        token: token,
      }).then((res) => {
        setMeterList(res);
      });
    }
  }, [converterGroupId]);

  const handleChangeConverterGroup = (changedValues) => {
    setConverterGroupId(changedValues);
    setSelectedMeterIds([]);
    setSelectedMeterNames([]);
  };

  const handleMeterChange = (selectedValues) => {
    setSelectedMeterIds(selectedValues);
    const selectedNames = meterList
      .filter((meter) => selectedValues.includes(meter.meteR_METERID))
      .map((meter) => meter.metername);
    setSelectedMeterNames(selectedNames);
  };

  const onChangeFromDate = (date, dateString) => {
    setFromDateString(dateString);
    setFromDateObj(date);
  };

  const onChangeToDate = (date, dateString) => {
    setToDateString(dateString);
    setToDateObj(date);
  };

  const handleSubmit = () => {
    const formattedFromDate = generatedISOFormatDateString(new Date(fromDateString));
    const formattedToDate = generatedISOFormatDateString(new Date(toDateString));
    const ids = selectedMeterIds.join(",");
    const inputData = {
      ConverterorGroupID: Number(converterGroupId),
      MeterIds: ids,
      FromDate: formattedFromDate,
      ToDate: formattedToDate,
    };

    makeRequest({
      type: APIConstants.GET,
      url: `${APIConstants.RAW_DATA}?ConverterID=${inputData.ConverterorGroupID}&MeterIds=${inputData.MeterIds}&FromDate=${inputData.FromDate}&ToDate=${inputData.ToDate}`,
      token: token,
    }).then((res) => {
      setReport(res);
    });
    onClose();
  };

  return (
    <div className="bg-white rounded-md w-auto">
      {loading && <Skeleton active paragraph={{ rows: 20 }} className="mt-12 mx-3" />}
      <div className="m-2">
        <RawGrid report={report} meters={selectedMeterNames} />
      </div>
      <CustomDrawer title="Close" showDrawer={showDrawer} open={open} onClose={onClose}>
        <div className="flex flex-col space-y-2">
          <CustomSelect
            label="Converter:"
            options={converterList}
            onSelect={handleChangeConverterGroup}
            valueKey={"sitE_ID"}
            nameKey={"sitename"}
          />
          <TreeSelectCustomWithSelectAll
            label="Meters:"
            data={meterList}
            valueKey={"meteR_METERID"}
            nameKey={"metername"}
            selectedValues={selectedMeterIds}
            setSelectedValues={setSelectedMeterIds}
            onSelect={handleMeterChange}
          />
          <div className="mt-2 flex">
            <label className="pr-3 w-32 text-right">From Date:</label>
            <DatePicker
              value={fromDateObj}
              onChange={onChangeFromDate}
              className="w-80"
              showTime={{ format: "HH:mm:ss" }}
              format="YYYY-MM-DD HH:mm:ss"
            />
          </div>
          <div className="mt-2 flex">
            <label className="pr-3 w-32 text-right">To Date:</label>
            <DatePicker
              value={toDateObj}
              onChange={onChangeToDate}
              className="w-80"
              showTime={{ format: "HH:mm:ss" }}
              format="YYYY-MM-DD HH:mm:ss"
            />
          </div>
        </div>
        <div className="flex justify-center mt-4">
          <Button className="blue-btn" onClick={handleSubmit}>
            Search
          </Button>
        </div>
      </CustomDrawer>
    </div>
  );
};

export default RawData;
